import getDashedTitle from '@/utilities/getDashedTitle';
import { translate } from '@/utilities';

function makeCardRouteHandler(store: any, router: any, _to: any, _from: any) {
  const previousRoute = sessionStorage.getItem('route');
  const teamSlug = store.getters.getTeamSlug;
  const reloadWithPreviousRoute =
    _from.fullPath === '/' && // Coming from the root path
    previousRoute &&
    previousRoute !== '/' &&
    previousRoute !== '/login' && // Previous route is not root or login
    !previousRoute.includes(`${teamSlug}/card-`); // Previous route doesn't include '/card-'
  const reloadWithoutPreviousRoute =
    _from.fullPath === '/' &&
    (!previousRoute || previousRoute === '/' || previousRoute === '/login');
  const cardOpenedFromSettings = previousRoute?.includes('/settings/');
  const toPath = _to.fullPath.replace(
    /\?userNavigated=true|&userNavigated=true|&cardIdButton=true|\?cardIdButton=true|\?cardMoved=true|&cardMoved=true/g,
    ''
  );
  const isCardMoved = _to.query?.cardMoved;
  const usePush =
    isCardMoved || (['inbox'].includes(_from.name) && !store.getters.getDisplayQuickCard);
  const isCardIdButtonClicked = _to.query?.cardIdButton;

  return function cardRouteHandler(data: any) {
    const card = data;
    if (
      isCardMoved ||
      reloadWithoutPreviousRoute ||
      isCardIdButtonClicked ||
      cardOpenedFromSettings ||
      (reloadWithoutPreviousRoute && store.getters.getIsSettingsOpen)
    ) {
      const boardId = card.sprint_id ? `${card.project_id}s${card.sprint_id}` : card.board_id;
      const projectId = card.project_id;
      const boardTitle = store.getters.getFlatBoards[boardId]?.title || '';
      const routeItemName = card.sprint_id ? 'sprint' : 'board';
      const dashedBoardTitle = getDashedTitle(boardTitle);
      const nextRoute = boardId
        ? `/${teamSlug}/${routeItemName}-${boardId}-${dashedBoardTitle}/${store.getters.cardsLayout()}`
        : `/${teamSlug}/space-${card.project_id}/boards`;
      const boardRouteWithoutCardsLayout = `/${teamSlug}/${routeItemName}-${boardId}-${dashedBoardTitle}`;
      if (
        router.currentRoute.fullPath !== nextRoute &&
        router.currentRoute.fullPath !== boardRouteWithoutCardsLayout
      ) {
        // if usePush then push route instead of replace route to allow back navigation to inbox
        const routerCommand = usePush ? 'push' : 'replace';

        router[routerCommand]({
          path: card.sprint_id ? boardRouteWithoutCardsLayout : nextRoute,
        }).then(() => {
          // After successful navigation to the previous route, update history
          history.replaceState({ cardId: _to.params.cardId }, '', toPath);
          localStorage.setItem('lastVisitedRoute', toPath);

          // Set and display the card
          store.dispatch('setDisplayQuickCard', true);
          store.dispatch('setCurrentCardId', _to.params.cardId);

          if (isCardIdButtonClicked) {
            store.dispatch('setCurrentBoardId', boardId);
            store.dispatch('setCurrentProjectId', projectId);
            store.dispatch('addUiNotification', {
              message: translate('boardOpenedInTheBackground', {
                boardTitle,
              }),
            });
          }
        });
      }
    } else if (reloadWithPreviousRoute) {
      router.replace({ path: previousRoute }).then(() => {
        history.replaceState({ cardId: _to.params.cardId }, '', toPath);
        localStorage.setItem('lastVisitedRoute', toPath);

        // Fetch and display the card
        store.dispatch('setDisplayQuickCard', true);
        store.dispatch('setCurrentCardId', _to.params.cardId);
      });
    }

    if (
      _to.query.userNavigated &&
      !reloadWithPreviousRoute &&
      !isCardIdButtonClicked &&
      !reloadWithoutPreviousRoute
    ) {
      history.pushState({ cardId: _to.params.cardId }, '', toPath);
      localStorage.setItem('lastVisitedRoute', toPath);
    }
  };
}

function cardBeforeEnterHandler(store: any, router: any, to: any, from: any, next: any) {
  // add function to handle card
  const cardRouteHandler = makeCardRouteHandler(store, router, to, from);
  // check if card is already on state don't fetch it again
  if (store.getters.getCards[to.params.cardId]) {
    cardRouteHandler(store.getters.getCards[to.params.cardId]);
  } else {
    store
      .dispatch('fetchCard', { cardId: to.params.cardId })
      .then((card: any) => {
        cardRouteHandler(card);
      })
      .catch(() => {
        if (from.path === '/') {
          next({
            name: 'cardnotfound',
            params: {
              teamSlug: store.getters.getTeamSlug,
              cardId: to.params.cardId,
              cardTitle: to.params.cardTitle,
            },
          });
        } else {
          store.dispatch('itemNotFound', true);
        }
      });
  }
}

export { makeCardRouteHandler, cardBeforeEnterHandler };
