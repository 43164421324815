/**
 * Onboarding module actions
 * @packageDocumentation
 * @category Store
 */
import { ActionTree } from 'vuex';
import { basePost, api, authGet, authPost } from '@/utilities/api';
import { OnboardingState as State, Team } from '@/types';
import {
  OnboardingActionStatus,
  OnboardingActionType,
  OnboardingAction,
} from '@/types/onboardingActions';
import constants from '@/utilities/constants';

const actions: ActionTree<State, any> = {
  /**
   * Send a login code to a user's email. User is created if they don't already exist.
   * https://api.superthread.com/v1/auth/code/send
   */
  createUser({ getters }) {
    return api.auth
      .sendLoginCodeEmail({
        email: getters.getEmail,
      })
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Log user in via email and email code
   * https://api.superthread.com/v1/auth/code
   */
  loginWithEmailCode({ getters }, code) {
    const { getEmail } = getters;
    return api.auth
      .loginWithCode({
        email: getEmail,
        code,
      })
      .catch((error) => {
        throw error;
      });
  },

  createOnboardingWorkspace({ getters, commit }) {
    const { team_name, company_name, sub_domain } = getters.onboardingWorkspace;

    return api.auth
      .createTeam({
        team_name,
        company_name,
        sub_domain,
      })
      .then(({ data }) => {
        commit('setOnboardingWorkspace', data.team);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  updateOnboardingWorkspaceDetails({ getters, commit }) {
    const { team_name, company_name, sub_domain } = getters.onboardingWorkspace;

    return api.auth
      .updateTeam(getters.onboardingWorkspace.id, {
        team_name,
        company_name,
        sub_domain,
      })
      .then(({ data }) => {
        commit('setOnboardingWorkspace', data.team);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  setOnboardingCurrentForm({ commit }, currentForm: string) {
    commit('setOnboardingCurrentForm', currentForm);
  },

  setOnboardingUserID({ commit }, userID: string) {
    commit('setOnboardingUserID', userID);
  },

  setOnboardingWorkspace({ commit }, value: Team) {
    commit('setOnboardingWorkspace', value);
  },

  setOnboardingErrorMessage({ commit }, errorMessage: string) {
    commit('setOnboardingErrorMessage', errorMessage);
  },

  setWorkspaceErrorMessage({ commit }, workspaceErrorMessage: string) {
    commit('setWorkspaceErrorMessage', workspaceErrorMessage);
  },

  setInvitationCode({ commit }, invitationCode: string) {
    commit('setInvitationCode', invitationCode);
  },

  setCurrentTeamId({ commit }, teamId) {
    commit('setCurrentTeamId', teamId);
  },

  setCurrentTeamName({ commit }, teamName) {
    commit('setCurrentTeamName', teamName);
  },

  /**
   * Get pending invites for a user
   * https://api.superthread.com/v1/users/{user_id}/pending_invites
   */
  getPendingInvities({ commit, getters }) {
    return api.auth
      .getPendingInvites(getters.getUser.id)
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Add user to a new team
   * https://api.superthread.com/v1/users/{user_id}/teams/{team_id}
   */
  joinTeam({ getters }, teamId) {
    return api.auth.addUserTeam(getters.getUser.id, teamId).then(({ data }) => data);
  },

  resetOnboardingState({ commit }) {
    commit('resetOnboardingState');
  },

  /**
   * Log user in via a team invite link.
   * https://api.superthread.com/v1/auth/invite
   */
  loginWithInviteLink({ getters }, { inviteId, code }) {
    return api.auth
      .loginWithInvite({
        invite_id: inviteId,
        link_code: code,
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Resend the invite if it was expired/lost.
   * https://api.superthread.com/v1/auth/invite/send
   */
  resendInviteLink({ getters }, { inviteId }) {
    return basePost(`${getters.getRoutes.users}/v1/auth/invite/send`, {
      invite_id: inviteId,
    }).catch((error) => {
      throw error;
    });
  },

  /**
   * Passes the OAuth authorization code to the BE, code gets exchanged for the refresh token with google and BE sets auth cookies
   * @param {string} code
   * @param {string} redirect_uri - url that was used to obtain the OAuth authorization code
   */
  passAuthCode({ getters }, { code, redirect_uri }) {
    //! DONT USE REQUEST FROM GENERATED MODELS AS IT IS INCORRECT
    //! request should be POST (as configured in /auth service)
    //! while one in generated models is set as GET (by mistake I guess)
    //? swap to next line when model is fixed
    // return api.auth.loginOAuthCode('google', {
    return authPost(`${getters.getRoutes.users}/v1/auth/idp/google/code`, {
      code,
      redirect_uri,
    });
  },

  /**
   * Import onboarding resources
   * /v1/{team_id}/imports/onboard
   */
  importOnboardingResources({ getters }) {
    const { getTeamID, getRoutes } = getters;
    return authGet(`${getRoutes.users}/v1/${getTeamID}/imports/onboard`)
      .then((data) => data)
      .catch((error) => error);
  },

  sendSignupAnswers({ getters }) {
    return api.auth
      .submitSignupAnswers(getters.getUser.id, {
        signup_answers: getters.getSignupAnswers,
      })
      .catch((error) => {
        throw error;
      });
  },

  setFailedInvites({ commit }, failedInvites) {
    commit('setFailedInvites', failedInvites);
  },

  storeSignupAnswers({ commit }, answers) {
    commit('storeSignupAnswers', answers);
  },

  /**
   * Sets flag for expanding the 'Get started' section in sidebar
   */
  setGetStartedExpanded({ commit }, value: boolean) {
    commit('setGetStartedExpanded', value);
  },

  /**
   * Sets flag for showing all items in the 'Get started' section in sidebar
   */
  setGetStartedShowAll({ commit }, value: boolean) {
    commit('setGetStartedShowAll', value);
  },

  /**
   * Fetches onboarding actions for the user
   */
  fetchOnboardingActions() {
    return api.auth
      .getOnboardingActions('me')
      .then(({ data }) => data.onboarding_actions)
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Sets onboarding actions for the user
   */
  setOnboardingActions({ commit }, actions: OnboardingAction[]) {
    commit('setOnboardingActions', actions);
  },

  /**
   * Completes multiple onboarding actions for the user
   * @param {OnboardingActionType[]} types - array of onboarding action types to complete
   */
  completeOnboardingActions({ commit, getters }, types: OnboardingActionType[]) {
    // check if user is create after release of onboarding actions to avoid firing unnecessary requests
    const userCreatedAfterOnboardingActionsRelease =
      getters.getUser.time_created > constants.onboardingActionsReleaseDate;
    if (!userCreatedAfterOnboardingActionsRelease) return;

    const actionsToUpdate = types
      .filter((type) => {
        return (
          getters.getOnboardingActions.find((a: OnboardingAction) => a.type === type)?.status !==
          OnboardingActionStatus.Completed
        );
      })
      .map((type) => ({
        status: OnboardingActionStatus.Completed,
        type,
      }));

    if (!actionsToUpdate.length) return;

    return api.auth
      .updateOnboardingActions('me', { onboarding_actions: actionsToUpdate })
      .then(({ data }) => commit('completeOnboardingActions', data.onboarding_actions))
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Dismisses a onboarding action for the user
   * @param {OnboardingActionType[]} types - array of onboarding action types to complete
   */
  dismissOnboardingAction({ commit }, type: OnboardingActionType) {
    return api.auth
      .updateOnboardingActions('me', {
        onboarding_actions: [
          {
            status: OnboardingActionStatus.Dismissed,
            type,
          },
        ],
      })
      .then(({ data }) => commit('updateOnboardingActionsStatus', data.onboarding_actions))
      .catch((error) => {
        throw error;
      });
  },

  setAsNotCompletedOnboardingAction({ commit }, actions: OnboardingAction[]) {
    return api.auth
      .updateOnboardingActions('me', {
        onboarding_actions: actions,
      })
      .then(({ data }) => commit('updateOnboardingActionsStatus', data.onboarding_actions))
      .catch((error) => {
        throw error;
      });
  },
};

export default actions;
