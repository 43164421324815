import Vue from 'vue';
import wrap from '@vue/web-component-wrapper';
import UserMention from './UserMention';
import StImage from './StImage';
import constants from '../utilities/constants';

const MentionCustomElement = wrap(Vue, UserMention);
const StImageCustomElement = wrap(Vue, StImage);

window.customElements.define('user-mention', MentionCustomElement);
window.customElements.define(constants.stImage, StImageCustomElement);
