export enum groupByOption {
  status = 'status',
  assignee = 'assignee',
  // owner = 'owner',
  tag = 'tag',
  priority = 'priority',
  // space = 'space',
  // board = 'board',
  none = 'none',
}
