const getListById = ({ listId, storeGetters }) => {
  if (storeGetters.lists[listId]) {
    return storeGetters.lists[listId];
  }

  return {};
};

const handleAddEventBoardView = ({
  newIndex,
  oldIndex,
  cardId,
  listId,
  originalListId,
  destinationBoardId,
  originalBoardId,
  storeGetters,
  storeDispatch,
  cardModel = null,
  order,
}) => {
  if (!cardId) return {};

  const { getListCards, getCards } = storeGetters;
  const destinationBoard = storeGetters.getFlatBoards[destinationBoardId];
  const originalBoard = storeGetters.getFlatBoards[originalBoardId];
  const isDestinationSprintBoard = destinationBoardId.includes('s');

  if (!getCards[cardId]) {
    storeDispatch('addCardToCards', { card: cardModel });
  }

  const newListObject = getListById({ listId, storeGetters });
  const { title, color, icon, behavior } = newListObject;

  if (
    handleListUpdate({
      cardId,
      newIndex,
      listId,
      originalListId,
      getListCards,
      storeDispatch,
    })
  )
    return null;

  updateCardFields({
    cardId,
    newListObject,
    listId,
    isDestinationSprintBoard,
    title,
    color,
    icon,
    behavior,
    storeDispatch,
  });
  if (originalBoardId !== destinationBoardId) {
    updateBoardFields({
      cardId,
      isDestinationSprintBoard,
      destinationBoard,
      destinationBoardId,
      storeDispatch,
    });
  }

  if (destinationBoard && originalBoard?.project_id !== destinationBoard?.project_id) {
    storeDispatch('localUpdateCardField', {
      cardId,
      fieldName: 'project_id',
      fieldValue: destinationBoard.project_id,
    });
  }

  return null;
};

const handleListUpdate = ({
  cardId,
  newIndex,
  listId,
  originalListId,
  getListCards,
  storeDispatch,
}) => {
  const originalList = [...(getListCards[originalListId] || [])];
  const newList = [...(getListCards[listId] || [])];

  if (newList[newIndex] === cardId) {
    return true;
  }

  if (originalList) {
    const originalIndex = originalList.indexOf(cardId);
    originalList.splice(originalIndex, 1);
    storeDispatch('setSingleListInListCards', {
      listId: originalListId,
      value: originalList,
    });

    if (originalListId === listId) {
      newList.splice(originalIndex, 1);
    }
  }

  if (newList) {
    newList.splice(newIndex, 0, cardId);
    storeDispatch('setSingleListInListCards', {
      listId,
      value: newList,
    });
  }

  return false;
};

const updateCardFields = ({
  cardId,
  newListObject,
  listId,
  isDestinationSprintBoard,
  title,
  color,
  icon,
  behavior,
  storeDispatch,
}) => {
  storeDispatch('localUpdateCardField', {
    cardId,
    fieldName: 'list_id',
    fieldValue: isDestinationSprintBoard ? newListObject.orginalId : listId,
  });

  storeDispatch('localUpdateCardField', {
    cardId,
    fieldName: 'list_title',
    fieldValue: title,
  });

  storeDispatch('localUpdateCardField', {
    cardId,
    fieldName: 'color',
    fieldValue: color,
  });

  storeDispatch('localUpdateCardField', {
    cardId,
    fieldName: 'list_color',
    fieldValue: color,
  });

  storeDispatch('localUpdateCardField', {
    cardId,
    fieldName: 'icon',
    fieldValue: icon,
  });

  storeDispatch('localUpdateCardField', {
    cardId,
    fieldName: 'status',
    fieldValue: behavior,
  });
};

const updateBoardFields = ({
  cardId,
  isDestinationSprintBoard,
  destinationBoard,
  destinationBoardId,
  storeDispatch,
}) => {
  storeDispatch('localUpdateCardField', {
    cardId,
    fieldName: isDestinationSprintBoard ? 'sprint_id' : 'board_id',
    fieldValue: isDestinationSprintBoard ? destinationBoard.orginalId : destinationBoardId,
  });

  storeDispatch('localUpdateCardField', {
    cardId,
    fieldName: 'board_title',
    fieldValue: destinationBoard?.title,
  });
};

const handleUpdateEventBoardView = ({
  newIndex,
  oldIndex,
  cardId,
  listId,
  storeDispatch,
  storeGetters,
}) => {
  const { getListCards } = storeGetters;

  const listCards = [...getListCards[listId]];
  if (listCards[newIndex] === cardId) return;

  listCards.splice(oldIndex, 1);
  listCards.splice(newIndex, 0, cardId);

  storeDispatch('setBoardListProp', {
    listId,
    propertyName: 'card_order',
    value: listCards,
  });

  storeDispatch('setSingleListInListCards', {
    listId,
    value: listCards,
  });
};

const addCard = ({
  cardModel,
  cardId,
  destinationProjectId,
  destinationBoardId,
  destinationListId,
  destinationPosition,
  originalProjectId,
  originalBoardId,
  originalListId,
  originalPosition,
  storeGetters,
  storeDispatch,
  route,
}) => {
  if (destinationListId !== originalListId) {
    handleAddEventBoardView({
      cardModel,
      cardId,
      newIndex: destinationPosition,
      oldIndex: originalPosition,
      listId: destinationListId,
      originalListId,
      destinationBoardId,
      originalBoardId,
      storeDispatch,
      storeGetters,
    });
  } else {
    handleUpdateEventBoardView({
      newIndex: destinationPosition,
      oldIndex: originalPosition,
      cardId,
      listId: destinationListId,
      storeDispatch,
      storeGetters,
    });
  }
};

export {
  addCard,
  handleAddEventBoardView,
  handleUpdateEventBoardView,
  updateCardFields,
  updateBoardFields,
  handleListUpdate,
  getListById,
};
