import styleVariables from '@/assets/scss/variables.module.scss';
import constants from './constants';

const getRootElement = () => document.documentElement;
const getRootStyles = () => getComputedStyle(getRootElement());
const getRootStyle = (varName) => getRootStyles().getPropertyValue(varName);
const setRootStyle = (varName, value) => getRootElement().style.setProperty(varName, value);
const removeRootStyle = (varName) => getRootElement().style.removeProperty(varName);

const convertStyleToNumber = (value) => parseFloat(value) || 0;
const getSidebarWidthLocal = () =>
  convertStyleToNumber(localStorage.getItem('sidebarWidth') || styleVariables.sidebarWidth);
const getDefaultSidebarWidth = () => convertStyleToNumber(styleVariables.sidebarWidth);
const getMaxSidebarWidth = () => convertStyleToNumber(styleVariables.sidebarMaxWidth);
const getMinSidebarWidth = () => convertStyleToNumber(styleVariables.sidebarMinWidth);
const getSidebarWidth = () => convertStyleToNumber(getRootStyle(constants.sidebarWidthRoot));
const getMaxChildPageItemInPageWidth = () =>
  convertStyleToNumber(styleVariables.childPageItemInPageMaxWidth);
const getPreviewChildPagesPaddingRight = () =>
  convertStyleToNumber(styleVariables.previewChildPagesPaddingRight);
const getBoardHeaderRightSideWidth = () =>
  convertStyleToNumber(styleVariables.boardHeaderRightSideWidth);
const getMobileBoardHeaderRightSideWidth = () =>
  convertStyleToNumber(styleVariables.mobileBoardHeaderRightSideWidth);
const getSpaceHeaderRightSideWidth = () =>
  convertStyleToNumber(styleVariables.spaceHeaderRightSideWidth);
const getMobileSpaceHeaderRightSideWidth = () =>
  convertStyleToNumber(styleVariables.mobileSpaceHeaderRightSideWidth);
const getPageBreadcrumbMaxWidth = () => convertStyleToNumber(styleVariables.pageBreadcrumbMaxWidth);
const getMobilePageBreadcrumbMaxWidth = () =>
  convertStyleToNumber(styleVariables.mobilePageBreadcrumbMaxWidth);
const getJoinProjectButtonWidth = () => convertStyleToNumber(styleVariables.joinProjectButtonWidth);
const getMobileSidebarWidth = () => convertStyleToNumber(styleVariables.mobileSidebarWidth);
const getTimelineSidebarWidthLocal = () =>
  convertStyleToNumber(
    localStorage.getItem('timelineSidebarWidth') || styleVariables.timelineSidebarWidth
  );
const getDefaultTimelineSidebarWidth = () =>
  convertStyleToNumber(styleVariables.timelineSidebarWidth);
const getMinTimelineSidebarWidth = () =>
  convertStyleToNumber(styleVariables.timelineSidebarMinWidth);
const getMaxTimelineSidebarWidth = () =>
  convertStyleToNumber(styleVariables.timelineSidebarMaxWidth);

const getInboxListWidthLocal = () =>
  convertStyleToNumber(localStorage.getItem('inboxListWidth') || styleVariables.inboxListWidth);
const getMinInboxListWidth = () => convertStyleToNumber(styleVariables.inboxListMinWidth);
const getMaxInboxListWidth = () => convertStyleToNumber(styleVariables.inboxListMaxWidth);
const getDefaultInboxListWidth = () => convertStyleToNumber(styleVariables.inboxListWidth);
const getDefaultLabelCutoffSidebarWidth = () =>
  convertStyleToNumber(styleVariables.defaultLabelCutoffSidebarWidth);
const getSearchLabelCutoffSidebarWidth = () =>
  convertStyleToNumber(styleVariables.searchLabelCutoffSidebarWidth);
const getStartedCountCutoffSidebarWidth = () =>
  convertStyleToNumber(styleVariables.getStartedCountCutoffSidebarWidth);
const getHeaderHeight = () => convertStyleToNumber(styleVariables.headerHeight);

const getCardWidthLocal = () =>
  convertStyleToNumber(localStorage.getItem('cardWidthLocal') || styleVariables.cardWidthWidth);
const getMinCardWidthWidth = () => convertStyleToNumber(styleVariables.dockedCardMinWidth);
const getMaxCardWidthWidth = () => convertStyleToNumber(styleVariables.dockedCardMaxWidth);
const getDefaultCardWidthWidth = () => convertStyleToNumber(styleVariables.dockedCardWidth);
const getSmallCardTagMaxWidth = () => convertStyleToNumber(styleVariables.smallCardTagMaxWidth);
const getAiSourceItemMaxWidth = () => convertStyleToNumber(styleVariables.aiSourceItemMaxWidth);

export {
  getRootStyle,
  setRootStyle,
  removeRootStyle,
  convertStyleToNumber,
  getSidebarWidthLocal,
  getDefaultSidebarWidth,
  getMaxSidebarWidth,
  getMinSidebarWidth,
  getSidebarWidth,
  getMaxChildPageItemInPageWidth,
  getPreviewChildPagesPaddingRight,
  getPageBreadcrumbMaxWidth,
  getMobilePageBreadcrumbMaxWidth,
  getBoardHeaderRightSideWidth,
  getMobileBoardHeaderRightSideWidth,
  getJoinProjectButtonWidth,
  getSpaceHeaderRightSideWidth,
  getMobileSpaceHeaderRightSideWidth,
  getMobileSidebarWidth,
  getTimelineSidebarWidthLocal,
  getDefaultTimelineSidebarWidth,
  getMinTimelineSidebarWidth,
  getMaxTimelineSidebarWidth,
  getInboxListWidthLocal,
  getMinInboxListWidth,
  getMaxInboxListWidth,
  getDefaultInboxListWidth,
  getDefaultLabelCutoffSidebarWidth,
  getSearchLabelCutoffSidebarWidth,
  getStartedCountCutoffSidebarWidth,
  getHeaderHeight,
  getCardWidthLocal,
  getMinCardWidthWidth,
  getMaxCardWidthWidth,
  getDefaultCardWidthWidth,
  getSmallCardTagMaxWidth,
  getAiSourceItemMaxWidth,
};
