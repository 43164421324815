export default {
  computed: {
    displayQuickCard() {
      return this.$store.getters.getDisplayQuickCard;
    },
    isCardOpen() {
      return this.displayQuickCard;
    },
  },
};
