<template>
  <div :style="style">
    <span>&nbsp;</span>{{ denotationChar }}{{ userLabel }}<span>&nbsp;</span>
  </div>
</template>
<script>
import styleVariables from '@/assets/scss/variables.module.scss';
import getMemberLabel from '@/utilities/getMemberLabel';

const staticStyle = {
  cursor: 'default',
  borderRadius: '4px',
  // padding: '0px 4px',
  display: 'inline',
  whiteSpace: 'nowrap',
};

export default {
  props: {
    userId: {
      type: String,
      required: true,
      default: '',
    },
    denotationChar: {
      type: String,
      required: false,
      default: '@',
    },
    userValue: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    style() {
      const theme = this.store.getters.getTheme;
      const isDarkTheme = theme === 'dark';
      let bgColor;
      if (this.isMe) {
        bgColor = isDarkTheme ? '#93370D' : styleVariables.warning100;
      } else {
        bgColor = isDarkTheme ? styleVariables.gray800 : styleVariables.white;
      }

      return {
        ...staticStyle,
        ...{
          backgroundColor: bgColor,
          color: isDarkTheme ? styleVariables.gray200 : styleVariables.gray800,
          boxShadow: isDarkTheme
            ? `0px 0px 0px 1px inset ${styleVariables.gray600}`
            : `0px 0px 0px 1px inset ${styleVariables.gray300}`,
        },
      };
    },
    store() {
      if (!window.StVueApp) return {};
      return window.StVueApp.$store;
    },
    user() {
      if (!window.StVueApp) return {};
      return this.store.getters.getMemberById(this.userId);
    },
    userLabel() {
      const userOb = Object.keys(this.user).length;
      if (!userOb) return this.userValue;
      return getMemberLabel(this.user);
    },
    isMe() {
      const currentUserId = this.store.getters.getUserId;
      return this.userId === currentUserId;
    },
  },
};
</script>
