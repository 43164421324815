<template>
  <div class="input-wrap">
    <label
      :for="inputId"
      class="input-field-label"
    >
      <span v-if="labelText">
        {{ translate(labelText) }}
      </span>
      <span
        v-show="lenghtLimitation.setLimit"
        class="input-length-limit"
      >
        {{ length }}
      </span>
    </label>
    <em
      v-if="icon"
      class="material-icons st-input-icon usn"
      @click="focusInput"
      >{{ icon }}</em
    >
    <em
      v-if="customIcon"
      class="st-input-custom-icon"
      :style="getCustomIconStyle(iconPath(customIcon))"
      @click="focusInput"
    />
    <input
      :id="inputId"
      ref="inputField"
      v-model="inputValue"
      v-autofocus="focusField"
      class="input-field"
      :class="[toggleClass, { 'st-input-icon-show': icon || customIcon }]"
      :maxLength="lenghtLimitation.maxLength"
      :placeholder="translate(placeholderText)"
      :type="inputType"
      :disabled="disabled"
      @keydown.enter.prevent.stop="handleEnter"
      @blur="validateOnBlur"
      @keypress="handleKeypress"
      @keyup.up.down.prevent="handleUpDownKeys"
      @keydown.up.down.prevent=""
      @keyup="handleKeyUp"
      @focus="handleFocus"
    />
    <em
      v-if="displayError"
      class="material-icons input-error-icon usn"
    >
      error_outline
    </em>
    <em
      v-if="clearInput && inputValue"
      class="st-input-custom-icon clear-input-icon"
      :style="getCustomIconStyle(iconPath('x'))"
      @click="handleClearInput"
    />
    <span
      v-if="displayError"
      class="error-message"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';
import customIconsMixin from '@/mixins/customIconsMixin';

export default {
  name: 'StInput',
  mixins: [customIconsMixin],
  props: {
    textValue: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      required: false,
      default: null,
    },
    placeholderText: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    inputHeight: {
      type: Number,
      required: false,
      default: 46,
    },
    focusField: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectField: {
      type: Boolean,
      required: false,
      default: false,
    },
    toggleClass: {
      type: String,
      required: false,
      default: '',
    },
    hasDropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    lenghtLimitation: {
      type: Object,
      required: false,
      default: () => ({
        setLimit: false,
        maxLength: {
          type: Number,
          required: false,
          default: null,
        },
      }),
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    customIcon: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearInput: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: '',
      displayError: false,
      errorFromBackend: null,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.textValue;
      },
      set(value) {
        this.$emit('input', value);
        this.value = this.textValue;
        if (value === '') {
          this.handleValidation(false);
        }
      },
    },
    inputId() {
      return nanoid(3);
    },
    error() {
      return this.errorFromBackend || this.errorMessage;
    },
    currentLength() {
      return this.inputValue.length;
    },
    length() {
      return `${this.currentLength}/${this.lenghtLimitation.maxLength}`;
    },
  },
  watch: {
    focusField(newValue) {
      if (newValue) {
        this.focusInput();
      }
    },
    selectField(newValue) {
      if (newValue) {
        this.selectInput();
      }
    },
    currentLength(newValue, oldValue) {
      if (this.displayError && newValue < oldValue) {
        this.$emit('length-limit', false);
      }
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.$refs.inputField.style.width = `${this.inputWidth}px`;
      this.$refs.inputField.style.height = `${this.inputHeight}px`;
    });
  },
  methods: {
    handleClearInput() {
      this.inputValue = '';
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.inputField.focus();
      });
    },
    selectInput() {
      this.$nextTick(() => {
        this.$refs.inputField.select();
      });
    },
    handleEnter($event) {
      if ($event.metaKey || $event.ctrlKey) {
        this.$emit('enter-ctrl-key', $event);
        return;
      }
      this.$emit('enter-key', $event);
    },
    handleValidation(value, errMessage = '') {
      this.displayError = value;
      if (errMessage) {
        this.errorFromBackend = errMessage;
      } else {
        this.errorFromBackend = null;
      }
    },
    validateOnBlur() {
      this.$emit('blur');
      if (this.inputValue === '') {
        return;
      }
      this.$emit('validate');
    },
    handleKeypress($event) {
      if (
        this.lenghtLimitation.setLimit &&
        this.lenghtLimitation.maxLength === this.currentLength
      ) {
        this.$emit('length-limit', true);
        $event.preventDefault();
      }
      if (this.hasDropdown) {
        this.$emit('keypress', $event);
      }
    },
    handleUpDownKeys($event) {
      if (this.hasDropdown) {
        this.$emit('updownkey', $event);
      }
    },
    handleFocus() {
      this.$emit('focus');
    },
    select() {
      this.$refs.inputField.select();
    },
    handleKeyUp($event) {
      this.$emit('keyup', $event);
    },
  },
};
</script>
