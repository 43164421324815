import { getCurrentInstance } from 'vue';

export type TranslateFn = (key: string, interpolations?: Record<string, string>) => string;

export default function useTranslate() {
  const _this = getCurrentInstance();
  const translate = (_this?.proxy as any).translate;
  return {
    translate: translate as TranslateFn,
  };
}
