import { RootState as IState } from '@/types';
import { Modal } from '@/types/modal';

const getDefaultState = (): IState => ({
  currentProjectId: '',

  currentEditCommentID: '',

  commentToDeleteId: '',
  commentToDeleteParentId: '',
  commentToDeleteResourceType: '',

  commentsAreActive: false,

  user: { team: {} },

  teams: {},
  teamProjects: [],
  projectOrder: [],
  socketDeletedProjects: [],

  comments: [],

  cardsCommentsAndActivities: {},

  commentsSection: 'pages',

  loggedIn: false,
  loginActionStatus: true,
  previousRoute: {},
  popUpMessage: '',

  loginDetails: {
    userName: '',
    email: '',
    password: '',
    teamID: '',
    teamSubDomain: localStorage.teamSubDomain || '',
    teamSubDomains: localStorage.getItem('teamSubDomains')
      ? JSON.parse(localStorage.teamSubDomains)
      : [],
  },

  emojiPickerDisplayed: '',

  routes: {
    local: {
      host: 'http://localhost:8080',
      users: 'http://localhost:12000',
      projects: 'http://localhost:12001',
      pages: 'http://localhost:12002',
      reactions: 'http://localhost:12003',
      comments: 'http://localhost:12004',
      boards: 'http://localhost:12005',
      lists: 'http://localhost:12006',
      cards: 'http://localhost:12007',
      checklists: 'http://localhost:12008',
      checklistitems: 'http://localhost:12009',
      search: '',
      files: '',
      views: 'http://localhost:12001',
      socket: '',
      integrations: '',
      editorSocket: 'ws://localhost:8787',
    },
    dev: {
      host: 'https://dev-app.superthread.com',
      users: 'https://api.dev.superthread.com',
      projects: 'https://api.dev.superthread.com',
      pages: 'https://api.dev.superthread.com',
      reactions: 'https://api.dev.superthread.com',
      comments: 'https://api.dev.superthread.com',
      boards: 'https://api.dev.superthread.com',
      lists: 'https://api.dev.superthread.com',
      cards: 'https://api.dev.superthread.com',
      checklists: 'https://api.dev.superthread.com',
      checklistitems: 'https://api.dev.superthread.com',
      search: 'https://api.dev.superthread.com',
      files: 'https://api.dev.superthread.com',
      views: 'https://api.dev.superthread.com',
      socket: 'wss://api.dev.superthread.com/v1',
      integrations: 'https://api.dev.superthread.com',
      editorSocket: 'wss://yjs-ws.superthread.workers.dev',
    },
    test: {
      host: 'https://dev-app.superthread.com',
      users: 'https://api.dev.superthread.com',
      projects: 'https://api.dev.superthread.com',
      pages: 'https://api.dev.superthread.com',
      reactions: 'https://api.dev.superthread.com',
      comments: 'https://api.dev.superthread.com',
      boards: 'https://api.dev.superthread.com',
      lists: 'https://api.dev.superthread.com',
      cards: 'https://api.dev.superthread.com',
      checklists: 'https://api.dev.superthread.com',
      checklistitems: 'https://api.dev.superthread.com',
      search: 'https://api.dev.superthread.com',
      files: 'https://api.dev.superthread.com',
      views: 'https://api.dev.superthread.com',
      socket: 'wss://api.dev.superthread.com/v1',
      integrations: 'https://api.dev.superthread.com',
      editorSocket: 'wss://yjs-ws.superthread.workers.dev',
    },
    staging: {
      host: 'https://staging-app.superthread.com',
      users: 'https://api.staging.superthread.com',
      projects: 'https://api.staging.superthread.com',
      pages: 'https://api.staging.superthread.com',
      reactions: 'https://api.staging.superthread.com',
      comments: 'https://api.staging.superthread.com',
      boards: 'https://api.staging.superthread.com',
      lists: 'https://api.staging.superthread.com',
      cards: 'https://api.staging.superthread.com',
      checklists: 'https://api.staging.superthread.com',
      checklistitems: 'https://api.staging.superthread.com',
      search: 'https://api.staging.superthread.com',
      files: 'https://api.staging.superthread.com',
      views: 'https://api.staging.superthread.com',
      socket: 'wss://api.staging.superthread.com/v1',
      integrations: 'https://api.staging.superthread.com',
      editorSocket: 'wss://collaboration-staging.superthread.com',
    },
    release: {
      host: 'https://app.superthread.com',
      users: 'https://api.superthread.com',
      projects: 'https://api.superthread.com',
      pages: 'https://api.superthread.com',
      reactions: 'https://api.superthread.com',
      comments: 'https://api.superthread.com',
      boards: 'https://api.superthread.com',
      lists: 'https://api.superthread.com',
      cards: 'https://api.superthread.com',
      checklists: 'https://api.superthread.com',
      checklistitems: 'https://api.superthread.com',
      search: 'https://api.superthread.com',
      files: 'https://api.superthread.com',
      views: 'https://api.superthread.com',
      socket: 'wss://api.superthread.com/v1',
      integrations: 'https://api.superthread.com',
      editorSocket: 'wss://collaboration.superthread.com',
    },
    production: {
      host: 'https://app.superthread.com',
      users: 'https://api.superthread.com',
      projects: 'https://api.superthread.com',
      pages: 'https://api.superthread.com',
      reactions: 'https://api.superthread.com',
      comments: 'https://api.superthread.com',
      boards: 'https://api.superthread.com',
      lists: 'https://api.superthread.com',
      cards: 'https://api.superthread.com',
      checklists: 'https://api.superthread.com',
      checklistitems: 'https://api.superthread.com',
      search: 'https://api.superthread.com',
      files: 'https://api.superthread.com',
      views: 'https://api.superthread.com',
      socket: 'wss://api.superthread.com/v1',
      integrations: 'https://api.superthread.com',
      editorSocket: 'wss://collaboration.superthread.com',
    },
  },
  projectSidebarDropdown: false,
  helpPopupIsOn: false,

  commentListOrderBy: 'time',

  projectFormName: '',
  projectFormDescription: '',

  currentTabName: 'boards',
  members: [],
  showSearchModal: false,
  searchResults: [],
  childSearchResults: [],
  lastSearchQuery: '',
  notificationMessage: '',
  notificationType: '',
  darkMode: false,
  cachedComponents: ['ProjectPages'],
  boardsBackground: '',
  sidebarExpanded: true,
  addMemberPopupIsOn: false,
  addMembersTarget: {},
  displayMembersDropdownId: '',

  displayEditLinkPopup: false,
  displayEditorTooltip: {
    editorID: '',
    show: false,
  },
  commentLinkUrl: '',
  commentLinkText: '',
  displayInfoSidebar: false,

  teamMembers: {},
  inactiveMembers: {},

  redirectRouteAfterLogin: {},
  notificationCount: 0,
  notificationAlerts: [],
  teamMemberNotifications: [],

  publicViews: [],
  privateViews: [],
  selectedViewType: 'card',
  viewPreview: [],
  selectedView: {},
  viewFilters: {
    include: {},
    exclude: {},
    sort_by: {
      field: 'time_created',
    },
  },
  viewPills: [],
  viewResultsList: {},
  viewEditMode: false,
  boardsFilters: [],
  uiNotifications: [],
  favouritesOpened: false,
  openedFavouriteItem: {},
  membersSearchInput: '',
  headerMemberDropdownPosition: {},
  activeComment: '',
  currentRoute: {},
  socketConnected: false,
  displayVideoLinkPopup: '',
  installations: {},
  importProgressSteps: {},
  routeBeforeSettings: '',
  importProgressCurrentStep: 1,
  settingsCurrentProjectId: '',
  settingsCurrentBoardId: '1',
  integrationSettings: {},
  projectSidebarExpanded: {},
  projectsExpanded: true,
  favouritesExpanded: true,
  draggingEntityProjectFromId: '',
  draggingSidebarItem: false,
  selectedProjectId: '',
  sdProjectSettingsDropdown: '',
  sdProjectNewPageBoardDropdown: '',
  sdPageSettingsDropdown: '',
  sdBoardSettingsDropdown: '',
  sdUserActionsDropdown: false,
  pendingInvites: [],
  currentRole: '',
  doubleCheckPopup: false,
  confirmationModal: false,
  previewAttachments: [],
  previewAttachmentId: '',
  previewAttachmentResourceId: '',
  previewAttachmentIndex: -1,
  attachmentPreviewFromInput: false,
  localCardIds: {},
  colorsPopup: false,
  commentsOrder: 'newest',
  quickCard: false,
  reopenQuickCard: false,
  membersToAddQuickCard: [],
  quickCardBoardId: '',
  quickPage: false,
  cardCommentActivityFilter: 'all',
  commentsInProgressPatch: [],
  commentsInProgressDelete: [],
  online: true,
  loadedFiles: [],
  projectPreviewLastScrollPos: {},
  favourites: [],
  favouritesStettingsDropdown: '',
  favouritesFolderExpanded: {},
  newFolderInput: false,
  importHistory: [],
  favouriteFolderForRename: '',
  favouriteFolderTitle: '',
  viewItemSettingsDropdown: '',
  showPeekSidebar: false,
  theme: '',
  electronBrowseHistory: [],
  electronBrowseHistoryIndex: -1,
  electronRecentlyViewed: [],
  themePreference: '',
  inviteeRole: '',
  inviteesProjectIds: [],
  redirectProjectId: 1,
  currentInboxResource: {},
  inboxFilter: 'unresolved',
  inboxListWidth: 350,
  inboxListExpanded: true,
  showOnboarding: false,
  iconPickerDropdown: '',
  manageProjectsItemSettingsDropdown: '',
  openInDesktopApp: JSON.parse(localStorage.getItem('openInDesktopApp') || 'null'),
  showRedirectedToAppOverlay: false,
  membersDropdownInvitees: [],
  editorForCardLink: '',
  myWorkSelectedTabId: {},
  myWorkUserId: '',
  manageMembersItemSettingsDropdown: '',
  memberHoverPreview: '',
  infoSidebarMemberItemSettingsDropdown: '',
  deletePopupId: '',
  itemNotFound: false,
  trashProjects: [],
  memberHoverPreviewInstances: [],
  boardsColumnLayout: JSON.parse(localStorage.getItem('boardsColumnLayout') ?? '{}'),
  pagesColumnLayout: JSON.parse(localStorage.getItem('pagesColumnLayout') ?? '{}'),
  pressedEscCancelDragging: false,
  currentViewTotalCount: 0,
  sidebarHovered: false,
  sidebarDragging: {},
  currentCardLayout: 'modal',
  teamFeatures: [],
  showUpdateEmailPopup: false,
  showHideReplies: {},
  editorOpen: {},
  pageIconPickerDisplayed: false,
  hideEmptyGroups: false,
  showWeekends: false,
  reconnectionCallbacks: {},
  previousNextButtonClicked: false,
  resolveInlineComment: false,
  notificationDigest: {},
  notificationDigestSettings: {},
  headerProjectSettingsDropdown: false,
  cardsLayoutOptionsDropdown: {},
  showTemplatesDropdown: '',
  boardEmbedPopup: {},
  isSettingsOpen: false,
  showUpgradePlanPopup: '',
  myWorkSortBy: {},
  showCardsWithNoSprintSidebar: false,
  modal: {} as Modal,
  sidebarSprintsExpanded: {},
});

export default getDefaultState;
