import boards from './boards';
import pages from './pages';
import onboarding from './onboarding';
import editors from './editors';

export default {
  boards,
  pages,
  onboarding,
  editors,
};
