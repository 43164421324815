/**
 * Board module mutations
 * @packageDocumentation
 * @category Store
 */
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { BoardState as State } from '@/types';
import getDefaultBoardState from './getDefaultBoardState';
import { isTokenExpired } from '@/utilities/jwtUtilities';
import {
  getQuickCardObjectName,
  getQuickCardFilesToUpdateName,
} from '@/utilities/getQuickCardObjectName';
import { convertChildCardDraftToChildCard } from '@/utilities/childCardDraft';
import { nanoid } from 'nanoid';

const mutations: MutationTree<State> = {
  setCurrentBoardId(state, boardId = '') {
    state.currentBoardId = boardId;
    if (boardId !== '') state.lastOpenedBoardId = boardId;
  },

  setBoardListsFlat(state, lists = []) {
    const flatLists: { [key: string]: any } = {};
    lists.forEach((l: any) => {
      if (l) flatLists[l.id] = l;
    });
    const newBoardLists = { ...state.lists, ...flatLists };
    Vue.set(state, 'lists', newBoardLists);
  },

  addBoardListId(state, { boardId, listId, position }) {
    if (!state.boardsListsIds[boardId]) {
      Vue.set(state.boardsListsIds, boardId, [listId]);
      return;
    }
    if (position != null) {
      state.boardsListsIds[boardId].splice(position, 0, listId);
      return;
    }
    state.boardsListsIds[boardId].push(listId);
  },

  replaceBoardListId(state, { boardId, localId, listId }) {
    const index = state.boardsListsIds[boardId].indexOf(localId);
    state.boardsListsIds[boardId].splice(index, 1, listId);
  },

  moveBoardListId(state, { boardId, listId, newIndex, oldIndex }) {
    if (state.boardsListsIds[boardId]) {
      state.boardsListsIds[boardId].splice(oldIndex, 1);
      state.boardsListsIds[boardId].splice(newIndex, 0, listId);
    }
  },

  removeBoardListId(state, { boardId, id }) {
    const index = state.boardsListsIds[boardId].indexOf(id);
    state.boardsListsIds[boardId].splice(index, 1);
  },

  removeBoardList(state, listId) {
    delete state.lists[listId];
  },

  removeMultipleBoardLists(state, listIds) {
    listIds.forEach((listId: any) => {
      delete state.lists[listId];
    });
  },

  addBoardInBoards(state, board) {
    if (state.projectBoards[board.project_id]) {
      state.projectBoards[board.project_id].push(board.id);
    }
    Vue.set(state.flatBoards, board.id, board);
  },

  removeBoardFromProjectBoards(state, board) {
    const projectBoards = state.projectBoards || {};
    const boardArray = projectBoards[board.project_id] || [];
    const boardIndex = boardArray.indexOf(board.id);
    if (boardIndex > -1) {
      projectBoards[board.project_id].splice(boardIndex, 1);
    }
    state.projectBoards = projectBoards;
  },

  setBoardsListsIds(state, boards) {
    const boardListsOrders: { [key: string]: any } = {};
    boards.forEach((b: any) => {
      boardListsOrders[b.id] = b.list_order;
    });
    const newBoardListOrders = { ...state.boardsListsIds, ...boardListsOrders };
    Vue.set(state, 'boardsListsIds', newBoardListOrders);
  },

  setSprintsListsIds(state, { sprintId, list, projectId }) {
    // set sprint lists ids with board list ids together
    const sprintLists: { [key: string]: any } = {};
    sprintLists[`${projectId}s${sprintId}`] = list;
    const newBoardListOrders = { ...state.boardsListsIds, ...sprintLists };
    Vue.set(state, 'boardsListsIds', newBoardListOrders);
  },

  setReportInfoData(state, { boardId, report }) {
    const reportInfoData = { ...state.reportInfoData };
    reportInfoData[boardId] = report;
    Vue.set(state, 'reportInfoData', reportInfoData);
  },

  setNewBoardProjectId(state, name) {
    state.newBoardProjectId = name;
  },

  setListDraftCardCreateView(state, listId) {
    state.listDraftCardCreateView = listId;
  },

  setRemovedBoardListPosition(state, { position, boardId, listId }) {
    state.removedListMeta = { position, boardId, listId };
  },

  setRemovedCardPosition(state, { position, cardId, boardId, listId }) {
    state.removedCardMeta = {
      position,
      cardId,
      boardId,
      listId,
    };
  },

  setTimeperoidOptions(state, value) {
    state.timeperiodOptions = value;
  },

  setDraggedEntity(state, payload) {
    Vue.set(state, 'draggedEntity', payload);
  },
  setDropTargetId(state, payload) {
    state.dropTargetId = payload;
  },

  toggleBoardQuickView(state, { boardId, value }) {
    Vue.set(state.expandedBoardIds, boardId, value);
  },

  toggleProjectBoards(state, { value, projectId }) {
    const { projectBoards, expandedBoardIds } = state;
    const boards = projectBoards[projectId] || [];
    if (boards.length === 0) return;
    const newExpandedBoardIds = { ...expandedBoardIds };
    boards.forEach((boardId: any) => {
      if (!expandedBoardIds[boardId]) return;
      newExpandedBoardIds[boardId] = value;
    });
    Vue.set(state, 'expandedBoardIds', newExpandedBoardIds);
  },
  toggleBoardsInRow(state, { boardId, value, projectId }) {
    const { projectBoards, expandedBoardIds } = state;
    const boards = projectBoards[projectId] || [];
    if (boards.length === 0) return;

    const boardIndex = boards.findIndex((board: any) => board === boardId);
    if (boardIndex === -1) return;

    const otherBoardIndex = boardIndex % 2 === 0 ? boardIndex + 1 : boardIndex - 1;
    if (otherBoardIndex >= boards.length) return;

    const newExpandedBoardIds = { ...expandedBoardIds };
    const otherBoardId = boards[otherBoardIndex];

    newExpandedBoardIds[boardId] = value;
    newExpandedBoardIds[otherBoardId] = value;
    Vue.set(state, 'expandedBoardIds', newExpandedBoardIds);
  },

  toggleAddNewChecklistItemInput(state, payload = false) {
    state.showAddNewChecklistItemInput = payload;
  },

  loadingBoards(state, payload = false) {
    state.loadingBoards = payload;
  },

  setBoardListProp(state, { listId, propertyName, value }) {
    if (!state.lists[listId]) return;
    Vue.set(state.lists[listId], propertyName, value);
  },

  setBoardViewNoDestroy(state, payload = false) {
    state.boardViewNoDestroy = payload;
  },

  setDraftListInputVisibleOnOpen(state, payload = false) {
    state.draftListInputVisibleOnOpen = payload;
  },

  localAddMemberToBoard(state, { memberToAdd, boardId }) {
    const board = state.flatBoards[boardId];

    if (board) {
      const members = [...board.members, memberToAdd];
      Vue.set(state.flatBoards[boardId], 'members', members);
    }
  },

  localRemoveMemberFromBoard(state, { memberToRemove, boardId }) {
    const board = state.flatBoards[boardId];

    if (board) {
      const members = [...board.members.filter((m: any) => m.user_id !== memberToRemove.user_id)];
      Vue.set(state.flatBoards[boardId], 'members', members);
    }
  },

  localAddMemberToCard(state, { cardId, memberToAdd }) {
    // add memeber to card if listCards object is not empty(if board view is opened)
    if (state.cards[cardId]) {
      const { members } = state.cards[cardId];
      const newMembers = [...(members || [])];
      if (members) {
        const alradyMember = members.find((m: any) => m.user_id === memberToAdd.user_id);
        if (alradyMember) return;
        newMembers.push(memberToAdd);
        Vue.set(state.cards[cardId], 'members', newMembers);
      }
    }
  },

  localRemoveMemberFromCardBoardView(state, { cardId, memberId }) {
    if (state.cards[cardId]) {
      const { members } = state.cards[cardId];
      const memberIndex = members.findIndex((m: any) => m.user_id === memberId);
      const newMembers = [...members];
      if (memberIndex > -1) {
        newMembers.splice(memberIndex, 1);
        Vue.set(state.cards[cardId], 'members', newMembers);
      }
    }
  },

  setBoardListView(state, value) {
    state.boardListView = value;
  },

  setBoardListOrderBy(state, orderBy) {
    state.boardListOrderBy = orderBy;
  },

  setCurrentTagForEdit(state, tagForEdit) {
    state.currentTagForEdit = tagForEdit;
  },

  setCardSidebarExpanded(state, value) {
    state.cardSidebarExpanded = value;
  },

  localAddTagToCard(state, { cardId, tag }) {
    if (state.cards[cardId]) {
      if (!state.cards[cardId].tags) Vue.set(state.cards[cardId], 'tags', []);
      const alradyTag = state.cards[cardId].tags.find((t: any) => t.id === tag.id);
      if (alradyTag) return;
      state.cards[cardId].tags.push(tag);
    }
  },
  updateLocalCardTag(state, { cardId, tagId, tag }) {
    if (state.cards[cardId]) {
      const newCardTags = [...state.cards[cardId].tags];
      const listCardTagIndex = newCardTags.findIndex((t: any) => t.id === tagId);
      if (listCardTagIndex !== -1) {
        newCardTags.splice(listCardTagIndex, 1, tag);
        Vue.set(state.cards[cardId], 'tags', newCardTags);
      }
    }
  },
  localAddTagToBoard(state, { boardId, tag }) {
    if (state.flatBoards[boardId]) {
      const board = state.flatBoards[boardId];

      if (!board.tags) {
        Vue.set(state.flatBoards[boardId], 'tags', []);
      }

      board.tags.push(tag);
    }
  },
  localDeleteTagFromCard(state, { cardId, tagId }) {
    if (state.cards[cardId]) {
      if (state.cards[cardId].tags) {
        const tags = [...state.cards[cardId].tags];
        const tagIndex = tags.findIndex((t: any) => t.id === tagId);
        if (tagIndex > -1) {
          tags.splice(tagIndex, 1);
          Vue.set(state.cards[cardId], 'tags', tags);
        }
      }
    }
  },
  localDeleteTagFromCards(state, { tagId }) {
    Object.keys(state.cards).forEach((cardId: any) => {
      if (state.cards[cardId].tags) {
        const tags = [...state.cards[cardId].tags];
        const tagIndex = tags.findIndex((t: any) => t.id === tagId);
        if (tagIndex > -1) {
          tags.splice(tagIndex, 1);
          Vue.set(state.cards[cardId], 'tags', tags);
        }
      }
    });
  },
  setLinkedCardType(state, value) {
    state.linkedCardType = value;
  },
  setShowImageUploadPopup(state, value) {
    state.showImageUploadPopup = value;
  },
  setCurrentCardIdForActionEdit(state, cardId) {
    state.currentCardIdActionEdit = cardId;
  },
  setCurrentListIdForActionEdit(state, listId) {
    state.currentListIdActionEdit = listId;
  },
  setCardsLayout(state, { boardId, value }) {
    Vue.set(state.cardsLayout, boardId, value);
  },
  replaceLocalChildCardIdInParentCard(state, { parentCardId, localId, newId }) {
    const parentCard = state.cards[parentCardId];
    if (parentCard) {
      const childCardOrder = [...(parentCard.child_card_order || [])];
      const updatedChildCardOrder = [...childCardOrder];
      const childCardIndex = updatedChildCardOrder.findIndex((i: any) => i === localId);
      if (childCardIndex > -1) {
        updatedChildCardOrder.splice(childCardIndex, 1, newId);
        Vue.set(parentCard, 'child_card_order', updatedChildCardOrder);
      }
    }
  },

  localUpdateCardFields(
    state,
    { cardId, card, overwrite = false, timeUpdated = null, userIdUpdated = null }
  ) {
    if (state.cards[cardId]) {
      const newCard = overwrite ? card : { ...state.cards[cardId], ...card };
      if (timeUpdated) {
        newCard.time_updated = timeUpdated;
      }
      if (userIdUpdated) {
        newCard.user_id_updated = userIdUpdated;
      }
      Vue.set(state.cards, cardId, newCard);
    }
  },

  localUpdateCardField(
    state,
    { cardId, fieldName, fieldValue, timeUpdated = null, userIdUpdated = null }
  ) {
    if (!cardId) return;
    Vue.set(state.cards[cardId], fieldName, fieldValue);
    if (timeUpdated && userIdUpdated) {
      Vue.set(state.cards[cardId], 'time_updated', timeUpdated);
      Vue.set(state.cards[cardId], 'user_id_updated', userIdUpdated);
    }
  },

  localUpdateCardFieldInMultipleCards(state, { cardIds, fieldName, fieldValue }) {
    const newCards: { [x: string]: any } = {};

    cardIds.forEach((cardId: string | number) => {
      newCards[cardId] = { ...state.cards[cardId], [fieldName]: fieldValue };
    });

    const newStateCards = { ...state.cards, ...newCards };

    Vue.set(state, 'cards', newStateCards);
  },

  setAttachments(state, { files, id, prefix }) {
    const resourceId = `${prefix}-${id}`;
    Vue.set(state.attachments, resourceId, files);
  },

  localAddAttachment(state, { attachment, id, prefix }) {
    const resourceId = `${prefix}-${id}`;
    if (!state.attachments[resourceId]) {
      Vue.set(state.attachments, resourceId, []);
    }
    state.attachments[resourceId].splice(0, 0, attachment);
  },

  localRemoveAttachment(state, { attachmentId, id, prefix }) {
    const resourceId = `${prefix}-${id}`;
    const newAttachments = [...(state.attachments[resourceId] || [])];
    const index = newAttachments.findIndex((a: any) => a.local_id === attachmentId);

    if (index > -1) {
      newAttachments.splice(index, 1);
      Vue.set(state.attachments, resourceId, newAttachments);
    }
  },

  addAttachmentUploadDetails(state, { id, localId, uploadDetails }) {
    const resourceId = `c-${id}`;
    const newAttachments = [...(state.attachments[resourceId] || [])];
    const attachmentIndex = newAttachments.findIndex(
      (attachment: any) => attachment.local_id === localId
    );

    if (attachmentIndex > -1) {
      newAttachments.splice(attachmentIndex, 1, {
        ...newAttachments[attachmentIndex],
        ...uploadDetails,
      });
      Vue.set(state.attachments, resourceId, newAttachments);
    }
  },

  localRemoveFileFromCard(state, { fileId, cardId }: { fileId: string; cardId: string }) {
    const cId = `c-${cardId}`;
    const newCardAttachments = [...state.attachments[cId]];

    const fileIndex = newCardAttachments.findIndex((f: any) => f.id === fileId);
    if (fileIndex !== -1) {
      newCardAttachments.splice(fileIndex, 1);
      Vue.set(state.attachments, cId, newCardAttachments);
    }
  },

  updateCardAttachmentId(state, { attachment, localId, cardId }) {
    const [file] = attachment;

    const resourceId = `c-${cardId}`;
    const newAttachemnts = [...state.attachments[resourceId]];
    const index = newAttachemnts.findIndex((a) => a.local_id === localId);
    if (index !== -1) {
      newAttachemnts.splice(index, 1, file);
      Vue.set(state.attachments, resourceId, newAttachemnts);
    }
  },

  setUploadFailedFlag(state, { localId, file, cardId, value = true }) {
    const attachmentsKey = `c-${cardId}`;

    const newAttachemnts = [...state.attachments[attachmentsKey]];
    const index = newAttachemnts.findIndex((a) => a.local_id === localId);
    if (index !== -1) {
      const newFile = {
        ...newAttachemnts[index],
        failed: value,
        file,
      };
      newAttachemnts.splice(index, 1, newFile);
      Vue.set(state.attachments, attachmentsKey, newAttachemnts);
    }
  },

  addCardFailedAttachment(state, attachment) {
    state.cardFailedAttachments.push(attachment);
  },

  resetBoardState(state) {
    Object.assign(state, getDefaultBoardState());
  },

  setCardAutoFocus(state, payload) {
    state.cardAutoFocus = payload;
  },

  setHeaderIconsDropdown(state, value) {
    state.headerIconsDropdown = value;
  },
  setPreviewPopoverKey(state, payload) {
    state.previewPopoverKey = payload;
  },

  setDisplayQuickCard(state, payload) {
    state.displayQuickCard = payload;
  },

  setAttachmentTargetCardId(state, id) {
    state.attachmentTargetCardId = id;
  },

  setCardAttachmentsLoaded(state, value) {
    state.cardAttachmentsLoaded = value;
  },
  setArchivedBoards(state, data = []) {
    state.archivedBoards = data;
  },

  setArchivedLists(state, data = []) {
    state.archivedLists = data;
  },

  setProjectPreviewBoards(state, { projectBoards = [], projectId }) {
    Vue.set(state.projectPreviewBoards, projectId, projectBoards);
  },

  setArchivedCards(state, data = []) {
    state.archivedCards = data;
  },

  addArchivedListToIndex(state, { list, index }) {
    state.archivedLists.splice(index, 0, list);
  },

  setNewBoardTitle(state, title) {
    state.newBoardTitle = title;
  },

  clearProjectPreviewBoards(state) {
    Vue.set(state, 'projectPreviewBoards', {});
  },

  setProjectPreview(state, project = {}) {
    state.previewProject = project;
  },

  displayProjectPreviewBoards(state, payload = false) {
    state.displayProjectPreviewBoards = payload;
  },

  setCurrentCardIdMemberDropdown(state, cardId) {
    state.currentCardIdMemberDropdown = cardId;
  },

  setCurrentCardMembersDropdown(state, members) {
    state.currentCardMembersDropdown = members;
  },

  setCurrentCardIdForPopup(state, cardId) {
    state.currentCardIdForPopup = cardId;
  },

  setCardAttachmentUploadingId(state, cardId) {
    state.cardAttachmentUploadingId = cardId;
  },

  setShowDuplicateCardPopup(state, payload) {
    state.showDuplicateCardPopup = payload;
  },

  setShowSetParentCardPopup(state, payload) {
    state.showSetParentCardPopup = payload;
  },

  updateBoardPropertyInBoards(state, { boardId, propertyName, value }) {
    if (state.flatBoards[boardId]) {
      Vue.set(state.flatBoards[boardId], propertyName, value);
    }
    if (state.currentBoardId === boardId) {
      Vue.set(state, 'currentBoard', value);
    }
  },

  setDraggingEnd(state, value) {
    state.draggingEnd = value;
  },

  setProjectBoards(state, { projectId, projectBoards }) {
    Vue.set(state.projectBoards, projectId, projectBoards);
  },

  removeProjectBoard(state, { projectId, boardId }) {
    if (state.projectBoards[projectId]) {
      const index = state.projectBoards[projectId].indexOf(boardId);
      state.projectBoards[projectId].splice(index, 1);
    }
  },

  addProjectBoard(state, { projectId, boardId, position = -1 }) {
    if (position > -1) {
      state.projectBoards[projectId].splice(position, 0, boardId);
      return;
    }
    state.projectBoards[projectId].push(boardId);
  },

  setFlatBoards(state, boardsArray) {
    const flatBoards: { [key: string]: any } = {};

    boardsArray.forEach((board: any) => {
      const fullyLoaded =
        state.flatBoards[board.id] &&
        (state.flatBoards[board.id].lists || []).length &&
        (state.flatBoards[board.id].lists || [])[0].cards != null;
      if (fullyLoaded) return;
      flatBoards[board.id] = board;
    });

    const newFlatBoards = { ...state.flatBoards, ...flatBoards };
    Vue.set(state, 'flatBoards', newFlatBoards);
  },

  setCards(state, cards) {
    const newCards = {
      ...state.cards,
      ...cards,
    };
    Vue.set(state, 'cards', newCards);
  },

  setListCards(state, listCards) {
    state.listCards = {
      ...state.listCards,
      ...listCards,
    };
  },

  setSingleListInListCards(state, { listId, value }) {
    Vue.set(state.listCards, listId, value);
  },

  addCardToListCards(state, { listId, cardId }) {
    const newListCards = [...(state.listCards[listId] || [])];
    newListCards.push(cardId);
    Vue.set(state.listCards, listId, newListCards);
  },

  removeCardFromListCards(state, { listId, cardId }) {
    if (state.listCards[listId]) {
      const index = state.listCards[listId].findIndex((id: any) => id === cardId);
      if (index > -1) {
        const newListCards = [...state.listCards[listId]];
        newListCards.splice(index, 1);
        state.listCards[listId] = newListCards;
      }
    }
  },

  addCardToCards(state, { card }) {
    const oldCard = state.cards[card.id] ?? {};
    let expired = true;
    if (oldCard?.collaboration?.token) {
      expired = isTokenExpired(oldCard.collaboration.token);
    }

    const newCard = {
      ...card,
      collaboration: expired ? card.collaboration : oldCard.collaboration,
    };
    Vue.set(state.cards, card.id || card.localCardId, newCard);
  },

  removeCardFromCards(state, cardId) {
    delete state.cards[cardId];
  },
  cardWsEventsUpdate(state, { change, cardId }) {
    const [cardProp, cardPropId, secondResource, thirdResource, fourthResource] = change.path;
    const card = state.cards[cardId];
    let cardIndex = -1;
    let cardTagIndex = -1;
    let checklistIndex = -1;
    if (Array.isArray(card[cardProp])) {
      cardIndex = card[cardProp].findIndex((c: any) => c.card_id === cardPropId);
    }

    switch (cardProp) {
      case 'hints':
        card.hints = change.to;
        break;
      case 'child_card_order':
        card.child_card_order = change.to || [];
        break;
      case 'tags':
        cardTagIndex = card.tags.findIndex((tag: any) => tag.id === cardPropId);
        if (card.tags[cardTagIndex]) {
          card.tags[cardTagIndex][secondResource] = change.to;
        }
        if (state.tags[cardPropId]) {
          state.tags[cardPropId][secondResource] = change.to;
          const newTags = { ...state.tags };
          Vue.set(state, 'tags', newTags);
        }
        break;
      case 'checklists':
        checklistIndex = card.checklists.findIndex((c: { id: any }) => c.id === cardPropId);
        if (change.path.includes('items')) {
          const itemId = thirdResource;
          const itemIndex = card.checklists[checklistIndex].items.findIndex(
            (item: any) => item.id === itemId
          );
          card.checklists[checklistIndex].items[itemIndex][fourthResource] = change.to;
          break;
        }
        card.checklists[checklistIndex][secondResource] = change.to;
        break;
      case 'checklist_item_order':
        card.checklist_item_order[cardPropId] = change.to;
        break;
      case 'epic':
      case 'parent_card':
        // title has been updated
        if (cardProp && cardPropId) {
          Vue.set(state.cards[cardId][cardProp], cardPropId, change.to);
          break;
        }

        // parent/card removed
        if (change.from && !change.to) {
          Vue.delete(state.cards[cardId], cardProp);
          break;
        }

        // parent/epic added
        if (change.to) {
          Vue.set(state.cards[cardId], cardProp, change.to);
          break;
        }

        break;
      case 'linked_cards':
        if (card[cardProp][cardIndex]?.[secondResource]) {
          card[cardProp][cardIndex][secondResource] = change.to;
        }
        if (state.cards[cardPropId]?.[secondResource]) {
          state.cards[cardPropId][secondResource] = change.to;
        }
        break;
      case 'child_cards':
        if (card[cardProp][cardIndex]?.[secondResource]) {
          card[cardProp][cardIndex][secondResource] = change.to;
        }
        if (state.cards[cardPropId]?.[secondResource]) {
          state.cards[cardPropId][secondResource] = change.to;
        }
        if (secondResource === 'archived') {
          if (change.to) {
            Vue.set(state.cards[cardPropId], 'archived', change.to);
          } else {
            delete state.cards[cardPropId].archived;
            const newStateCards = { ...state.cards };
            Vue.set(state, 'cards', newStateCards);
          }
        }
        break;
      case 'external_links':
        break;
      default:
        // check if the property is a card property
        if (card[cardProp] !== undefined) {
          card[cardProp] = change.to;
        } else {
          const newCard = { ...state.cards[cardId], [cardProp]: change.to };
          Vue.set(state.cards, cardId, newCard);
        }
        break;
    }
  },

  cardWsEventsCreate(state, { change, cardId, projectId, teamId }) {
    const [cardProp, cardPropId] = change.path;
    const card = state.cards[cardId];
    switch (cardProp) {
      case 'hints':
        card.hints = change.to;
        break;
      case 'checklists':
        if (change.path.includes('items')) {
          const checklistIndex = card.checklists.findIndex((c: { id: any }) => c.id === cardPropId);
          card.checklists[checklistIndex]?.items.push(change.to);
        } else if (change.to.id) {
          card.checklists.push(change.to);
        }
        break;
      case 'checklist_item_order':
        card.checklist_item_order[cardPropId] = change.to;
        break;
      case 'start_date':
      case 'due_date':
      case 'child_card_order':
        card[cardProp] = change.to;
        break;
      case 'tags':
        if (card[cardProp].findIndex((tag: any) => tag.id === change.to.id) === -1) {
          card[cardProp].push(change.to);
        }
        state.tags[change.to.id] = change.to;
        if (
          !state.projectTags[projectId]?.includes(change.to.id) &&
          !state.workspaceTags[teamId]?.includes(change.to.id)
        ) {
          state.projectTags[projectId]?.push(change.to.id);
        }
        break;
      case 'external_links':
        break;
      default:
        if (Array.isArray(card[cardProp])) {
          card[cardProp].push(change.to);
        }
        break;
    }
  },

  cardWsEventsDelete(state, { change, cardId }) {
    const [cardProp, cardPropId, secondResource, thirdResource] = change.path;
    const card = state.cards[cardId];
    switch (cardProp) {
      case 'hints':
        if (card.hints[cardPropId]) {
          card.hints.splice(cardPropId, 1);
        }
        break;
      case 'child_card_order':
        card.child_card_order = change.to || [];
        break;
      case 'tags':
        const tagId = change.from.id;
        const index = card.tags.findIndex((tag: any) => tag.id === tagId);
        card.tags.splice(index, 1);
        break;
      case 'checklists':
        if (change.path.includes('items')) {
          const itemId = thirdResource;
          if (card.checklists) {
            const checklistIndex = card.checklists.findIndex(
              (c: { id: any }) => c.id === cardPropId
            );
            const itemIndex = card.checklists[checklistIndex].items?.findIndex(
              (item: any) => item.id === itemId
            );
            card.checklists[checklistIndex].items?.splice(itemIndex, 1);
          }
          break;
        }
        const checklistId = change.from.id;
        const checklistIndex = card.checklists.findIndex(
          (checklist: any) => checklist.id === checklistId
        );
        card.checklists.splice(checklistIndex, 1);
        break;
      case 'members':
        const memberIndex = card.members.findIndex((member: any) => member.user_id === cardPropId);
        card.members.splice(memberIndex, 1);
        break;
      case 'start_date':
        card.start_date = 0;
        break;
      case 'due_date':
        card.due_date = 0;
        break;
      case 'child_cards':
      case 'linked_cards':
        const cardIndex = card[cardProp].findIndex((c: any) => c.card_id === cardPropId);
        card[cardProp].splice(cardIndex, 1);
        break;
      case 'external_links':
        break;
    }
  },

  setCurrentCardId(state, cardId) {
    state.currentCardId = cardId;
  },

  removeCardFromArchived(state, cardId) {
    const index = state.archivedCards.findIndex((c: any) => c.id === cardId);

    if (index > -1) {
      const newArchivedCards = [...state.archivedCards];

      newArchivedCards.splice(index, 1);
      state.archivedCards = newArchivedCards;
    }
  },

  setChecklistCollapsedIds(state, checklistIds) {
    state.collapsedChecklistIds = checklistIds;
  },

  setHiddenItemChecklistIds(state, checklistIds) {
    state.hiddenItemsChecklistIds = checklistIds;
  },

  toggleAddNewChecklistInput(state, payload = false) {
    state.showAddNewChecklistInput = payload;
  },

  showFilterMenu(state, payload = false) {
    state.showFilterMenu = payload;
  },

  showSubHeaderFilterMenu(state, payload = false) {
    state.showSubHeaderFilterMenu = payload;
  },

  setBoardFilters(state, { boardId, filters, teamId }) {
    const boardsFilters = { ...state.boardsFilters };
    boardsFilters[boardId] = filters;
    Vue.set(state, 'boardsFilters', boardsFilters);
    localStorage.setItem(`boardsFilters-${teamId}`, JSON.stringify(boardsFilters));
  },

  setBoardPills(state, { boardId, pills }) {
    const boardsPills = { ...state.boardsPills };
    boardsPills[boardId] = pills;
    Vue.set(state, 'boardsPills', boardsPills);
  },

  setTags(state, { tagsArray, teamId, projectId = null }) {
    const tags: { [key: string]: any } = {};
    let projectTags: any = [];
    let workspaceTags: any = [];

    tagsArray.forEach((tag: any) => {
      tags[tag.id] = tag;
      if (tag.project_id) {
        projectTags = [...(projectTags || []), tag.id];
      } else {
        workspaceTags = [...(workspaceTags || []), tag.id];
      }
    });

    const newTags = { ...state.tags, ...tags };
    Vue.set(state, 'tags', newTags);
    if (projectId) {
      Vue.set(state.projectTags, projectId, projectTags);
    }
    Vue.set(state.workspaceTags, teamId, workspaceTags);
  },

  deleteLocalTag(state, { tagId }) {
    const tag = state.tags[tagId];
    if (tag.project_id) {
      const projectTags = [...(state.projectTags[tag.project_id] || [])];
      const index = projectTags.indexOf(tagId);
      if (index > -1) {
        projectTags.splice(index, 1);
        Vue.set(state.projectTags, tag.project_id, projectTags);
      }
    } else {
      const workspaceTags = [...(state.workspaceTags[tag.team_id] || [])];
      const index = workspaceTags.indexOf(tagId);
      if (index > -1) {
        workspaceTags.splice(index, 1);
        Vue.set(state.workspaceTags, tag.team_id, workspaceTags);
      }
    }
    const newTags = { ...state.tags };
    delete newTags[tagId];
    Vue.set(state, 'tags', newTags);
  },

  addLocalTag(state, { tag }) {
    Vue.set(state.tags, tag.id, tag);
    if (tag.project_id) {
      const projectTags = [...(state.projectTags[tag.project_id] || []), tag.id];
      Vue.set(state.projectTags, tag.project_id, projectTags);
    } else {
      const workspaceTags = [...(state.workspaceTags[tag.team_id] || []), tag.id];
      Vue.set(state.workspaceTags, tag.team_id, workspaceTags);
    }
  },

  updateLocalTag(state, { tagId, tag, cardId }) {
    if (state.tags[tagId]) {
      const updatedTag = { ...state.tags[tagId], ...tag };
      Vue.set(state.tags, tagId, updatedTag);
      if (cardId) {
        const tagIndex = state.cards[cardId].tags.findIndex((tag: { id: any }) => tag.id === tagId);
        if (tagIndex !== -1) {
          state.cards[cardId].tags[tagIndex] = updatedTag;
        }
      } else {
        const cards = Object.values(state.cards);
        cards.forEach((card: any) => {
          const tagIndex = card?.tags?.findIndex?.((tag: { id: any }) => tag.id === tagId);
          if (tagIndex !== -1 && card?.tags) {
            card.tags[tagIndex] = updatedTag;
          }
        });
      }
    }
  },

  removeLocalPropFromTag(state, { tagId, prop }) {
    if (state.tags[tagId]) {
      const newTag = { ...state.tags[tagId] };
      delete newTag[prop];
      Vue.set(state.tags, tagId, newTag);
    }
  },

  updateLocalTagId(state, { localTagId, tag }) {
    if (tag.project_id) {
      const projectTags = [...(state.projectTags[tag.project_id] || [])];
      const index = projectTags.indexOf(localTagId);
      if (index > -1) {
        projectTags.splice(index, 1, tag.id);
        Vue.set(state.projectTags, tag.project_id, projectTags);
      }
    } else {
      const workspaceTags = [...(state.workspaceTags[tag.team_id] || [])];
      const index = workspaceTags.indexOf(localTagId);
      if (index > -1) {
        workspaceTags.splice(index, 1, tag.id);
        Vue.set(state.workspaceTags, tag.team_id, workspaceTags);
      }
    }
  },

  setCardCommentAttachmentUploadPopup(state, value) {
    state.cardCommentAttachmentUploadPopup = value;
  },

  setCardDescriptionAttachmentUploadPopup(state, value) {
    state.cardDescriptionAttachmentUploadPopup = value;
  },

  setLastOpenedBoardId(state, boardId = '') {
    state.lastOpenedBoardId = boardId;
  },

  setBoardList(state, { listId, list }) {
    Vue.set(state.lists, listId, list);
  },

  removeBoardFromBoards(state, boardId) {
    const newFlatBoards = { ...state.flatBoards };
    delete newFlatBoards[boardId];

    Vue.set(state, 'flatBoards', newFlatBoards);
  },

  removeBoardsListsIds(state, boardId) {
    const newBoardsListsIds = { ...state.boardsListsIds };
    delete newBoardsListsIds[boardId];

    Vue.set(state, 'boardsListsIds', newBoardsListsIds);
  },

  lastHoveredListId(state, listId) {
    state.lastHoveredListId = listId;
  },

  lastHoveredCardId(state, cardId) {
    state.lastHoveredCardId = cardId;
  },

  lastHoveredDisplayedCardId(state, cardId) {
    state.lastHoveredDisplayedCardId = cardId;
  },

  setSelectedCardId(state, cardId) {
    state.selectedCardId = cardId;
  },

  setBoardViewMouseSuspended(state, value) {
    state.boardViewMouseSuspended = value;
  },

  setCustomColorPicker(state, value) {
    state.customColorPicker = value;
  },
  setChecklistInputActive(state, { checklistId, isActive }) {
    const tempActiveChecklists = { ...state.checklistInputActive };
    if (!isActive) {
      delete tempActiveChecklists[checklistId];
      Vue.set(state, 'checklistInputActive', tempActiveChecklists);
    } else {
      Vue.set(state.checklistInputActive, checklistId, isActive);
    }
  },
  setChecklistItemInputActive(state, { checklistItemId, isActive }) {
    const tempActiveChecklistItems = { ...state.checklistItemInputActive };
    if (!isActive) {
      delete tempActiveChecklistItems[checklistItemId];
      Vue.set(state, 'checklistItemInputActive', tempActiveChecklistItems);
    } else {
      Vue.set(state.checklistItemInputActive, checklistItemId, isActive);
    }
  },
  setCardOwnerId(state, { ownerId, cardId }: { ownerId: string; cardId: string }) {
    Vue.set(state.cards[cardId], 'owner_id', ownerId);
  },

  setCardTemplates(state, templatesArray) {
    const flatTemplates: { [key: string]: any } = {};
    templatesArray.forEach((template: any) => {
      flatTemplates[template.id] = template;
    });
    const newTemplates = { ...flatTemplates };
    Vue.set(state, 'cardTemplates', newTemplates);
  },

  setCardTemplate(state, template) {
    Vue.set(state.cardTemplates, template.id, template);
  },

  replaceCardTemplateId(state, { localId, template }) {
    const newTemplates = { ...state.cardTemplates };
    delete newTemplates[localId];
    newTemplates[template.id] = template;
    Vue.set(state, 'cardTemplates', newTemplates);
  },

  deleteCardTemplate(state, templateId) {
    const newTemplates = { ...state.cardTemplates };
    delete newTemplates[templateId];
    Vue.set(state, 'cardTemplates', newTemplates);
  },

  setCardTemplateDraft(state, template) {
    const newTemplate = {
      card_board_id: template.card_board_id,
      card_content: template.card_content,
      card_list_id: template.card_list_id,
      card_members: template.card_members,
      card_priority: template.card_priority,
      card_tags: template.card_tags,
      card_title: template.card_title,
      card_child_cards: template.card_child_cards,
      icon: template.icon,
      project_ids: template.project_ids,
      title: template.title,
    };
    state.cardTemplateDraft = newTemplate;
  },

  setMembersToCardTemplate(state, value) {
    const { card_members } = state.cardTemplateDraft;
    let member = value;
    card_members.forEach((addedMember: any) => {
      if (value.user_id === addedMember.user_id) {
        card_members.splice(card_members.indexOf(addedMember), 1);
        member = {};
      }
    });
    if (member === value) card_members.push(value);
  },

  updateCardTemplateDraftField(state, { fieldName, value }) {
    Vue.set(state.cardTemplateDraft, fieldName, value);
  },

  clearCardTemplateDraft(state) {
    state.cardTemplateDraft = {
      title: '',
      icon: {},
      project_ids: [],
      card_title: '',
      card_content: '',
      card_board_id: '',
      card_list_id: '',
      card_members: [],
      card_tags: [],
      card_priority: 0,
      card_child_cards: [],
    };
  },

  addTagToTemplate(state, tagId) {
    if (!state.cardTemplateDraft.card_tags.length) {
      state.cardTemplateDraft.card_tags?.push?.({ ids: [tagId] });
      return;
    }
    state.cardTemplateDraft.card_tags[0]?.ids?.push?.(tagId);
  },

  manageCardTemplateSelectedSpaces(state, spaceId) {
    const { project_ids } = state.cardTemplateDraft;
    const index = project_ids.indexOf(spaceId);
    if (index > -1) {
      project_ids.splice(index, 1);
    } else {
      project_ids.push(spaceId);
    }
  },

  toggleMemberTemplateChildCard(state, { index, member }) {
    const { card_child_cards } = state.cardTemplateDraft;
    if (!card_child_cards) return;
    const card = card_child_cards[index];
    if (!card) return;
    const memberIndex = card.members.findIndex((m: any) => m.user_id === member.user_id);
    if (memberIndex > -1) {
      card.members.splice(memberIndex, 1);
    } else {
      card.members.push(member);
    }
  },

  addChildCardToTemplate(state) {
    const { card_child_cards } = state.cardTemplateDraft;
    if (!card_child_cards) {
      state.cardTemplateDraft.card_child_cards = [];
    }
    const card = convertChildCardDraftToChildCard(state.quickChildCardObject);
    state.cardTemplateDraft.card_child_cards.push(card);
  },

  updateChildCardInTemplate(state, index) {
    const { card_child_cards } = state.cardTemplateDraft;
    if (!card_child_cards) return;
    const card = card_child_cards[index];
    if (!card) return;
    const newCard = convertChildCardDraftToChildCard(state.quickChildCardObject);
    Vue.set(card_child_cards, index, newCard);
  },

  removeChildCardFromTemplate(state, index) {
    const { card_child_cards } = state.cardTemplateDraft;
    if (!card_child_cards) return;
    card_child_cards.splice(index, 1);
  },

  updateTemplateChildCardProperty(state, { fieldName, value, index }) {
    const { card_child_cards } = state.cardTemplateDraft;
    if (!card_child_cards) return;
    const card = card_child_cards[index];
    if (!card) return;
    Vue.set(card, fieldName, value);
  },

  setTimelineZoom(state, zoom) {
    state.timelineZoom = zoom;
  },

  setTimelineSidebarWidth(state, width) {
    state.timelineSidebarWidth = width;
  },

  setCardWidth(state, width) {
    state.cardWidth = width;
  },

  setParentCardActionsDropdownId(state, value) {
    state.parentCardActionsDropdownId = value;
  },

  setChildCardActionsDropdownId(state, value) {
    state.childCardActionsDropdownId = value;
  },

  setChildCardStatusPickerDropdownId(state, value) {
    state.childCardStatusPickerDropdownId = value;
  },

  setListCardStatusPickerDropdownId(state, value) {
    state.listCardStatusPickerDropdownId = value;
  },

  setChecklistActionsDropdownId(state, value) {
    state.checklistActionsDropdownId = value;
  },

  closeCardUploadPopup(state) {
    state.cardCommentAttachmentUploadPopup = {};
    state.cardDescriptionAttachmentUploadPopup = {};
  },

  addQuickCardFileToUpdate(state, { file, isModal }) {
    const propertyName = getQuickCardFilesToUpdateName(isModal);
    state[propertyName].push(file);
  },

  removeQuickCardFilesToUpdate(state, isModal) {
    const propertyName = getQuickCardFilesToUpdateName(isModal);
    state[propertyName] = [];
  },

  setQuickCardTitle(state, { title, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    state[propertyName].title = title;
  },

  setMembersToAddQuickCard(state, { value, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    let member = value;
    state[propertyName].members.forEach((addedMember: any) => {
      if (value.user_id === addedMember.user_id) {
        state[propertyName].members.splice(state[propertyName].members.indexOf(addedMember), 1);
        member = {};
      }
    });
    if (member === value) state[propertyName].members.push(value);
  },

  setQuickCardField(state, { fieldName, value, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    Vue.set(state[propertyName], fieldName, value);
  },

  clearQuickCardObject(state, isModal) {
    const propertyName = getQuickCardObjectName(isModal);
    Vue.set(state, propertyName, {
      localId: nanoid(6),
      title: '',
      content: '',
      tags: [],
      attachments: [],
      members: [],
      parentCard: {},
      projectId: '',
      priority: 0,
      board: {},
      hints: [],
      childCards: [],
    });
  },

  updateQuickCardChildCardProperty(state, { fieldName, value, index, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    const { childCards } = state[propertyName];
    if (!childCards) return;
    const card = childCards[index];
    if (!card) return;
    Vue.set(card, fieldName, value);
  },

  toggleMemberQuickCardChildCard(state, { index, member, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    const { childCards } = state[propertyName];
    if (!childCards) return;
    const card = childCards[index];
    if (!card) return;
    const memberIndex = card.members.findIndex((m: any) => m.user_id === member.user_id);
    if (memberIndex > -1) {
      card.members.splice(memberIndex, 1);
    } else {
      card.members.push(member);
    }
  },

  setQuickCardProjectId(state, { projectId, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    state[propertyName].projectId = projectId;
  },

  addTagToQuickCard(state, { tag, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    state[propertyName].tags.push(tag);
  },

  removeTagFromQuickCard(state, { tagId, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    const index = state[propertyName].tags.findIndex((t: any) => t.id === tagId);

    if (index >= 0) {
      state[propertyName].tags.splice(index, 1);
    }
  },

  addChildCardToQuickCard(state, isModal) {
    const propertyName = getQuickCardObjectName(isModal);
    const { childCards } = state[propertyName];
    if (!childCards) {
      state[propertyName].childCards = [];
    }
    const card = convertChildCardDraftToChildCard(state.quickChildCardObject);
    state[propertyName].childCards.push(card);
  },

  updateChildCardInQuickCard(state, { index, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    const { childCards } = state[propertyName];
    if (!childCards) return;
    const card = childCards[index];
    if (!card) return;
    const newCard = convertChildCardDraftToChildCard(state.quickChildCardObject);
    Vue.set(childCards, index, newCard);
  },

  removeChildCardFromQuickCard(state, { index, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    const { childCards } = state[propertyName];
    if (!childCards) return;
    childCards.splice(index, 1);
  },

  setParentCardForQuickCard(state, parentCard) {
    state.quickCardObject.parentCard = parentCard;
  },

  setCardIdForMove(state, cardId) {
    state.cardIdForMove = cardId;
  },
  setCardSliderActive(state, value) {
    state.cardSliderActive = value;
  },

  setBoardSortBy(state, { boardId, value, teamId }) {
    const boardSortBy = { ...state.boardSortBy };
    boardSortBy[boardId] = value;
    Vue.set(state, 'boardSortBy', boardSortBy);
    localStorage.setItem(`boardSortBy-${teamId}`, JSON.stringify(boardSortBy));
  },

  setBoardGroupBy(state, { boardId, value, teamId }) {
    const boardGroupBy = { ...state.boardGroupBy };
    boardGroupBy[boardId] = value;
    Vue.set(state, 'boardGroupBy', boardGroupBy);
    localStorage.setItem(`boardGroupBy-${teamId}`, JSON.stringify(boardGroupBy));
  },

  setChildCardCreation(state, value) {
    state.childCardCreation = value;
  },

  setCardCustomSectionDropdown(state, payload) {
    state.cardCustomSectionDropdown = payload;
  },

  setQuickCardObject(state, { quickCardObject, isModal }) {
    const propertyName = getQuickCardObjectName(isModal);
    const quickCard = {
      ...quickCardObject,
      // add localId if it does not exist
      ...(!quickCardObject.localId && { localId: nanoid(6) }),
    };
    Vue.set(state, propertyName, quickCard);
  },

  setQCSuggestions(state, suggestions) {
    state.quickCardObject.hints = suggestions;
  },

  setShowSuggestionsPlaceholder(state, showPlaceholder) {
    state.showSuggestionsPlaceholder = showPlaceholder;
  },
  setQCShowSuggestionsPlaceholder(state, showPlaceholder) {
    state.qcShowSuggestionsPlaceholder = showPlaceholder;
  },

  setBoardListGroupByOrder(state, { boardId, listIds, groupBy, teamId }) {
    const boardListGroupByOrder = { ...state.boardListGroupByOrder };
    if (!boardListGroupByOrder[boardId]) {
      boardListGroupByOrder[boardId] = {};
    }
    if (!boardListGroupByOrder[boardId][groupBy]) {
      boardListGroupByOrder[boardId][groupBy] = { order: listIds };
    } else {
      const currentOrder = boardListGroupByOrder[boardId][groupBy].order;

      // Create a Set from the new array to remove duplicates
      const newOrder = new Set(listIds);

      // Filter the current array to remove elements that are not in the new array
      const orderedArray = currentOrder.filter((id: string) => newOrder.has(id));

      // Add elements from the new array that are not in the current array
      listIds.forEach((id: string) => {
        if (!orderedArray.includes(id)) {
          orderedArray.push(id);
        }
      });
      boardListGroupByOrder[boardId][groupBy].order = orderedArray;
    }
    for (let index = 0; index < listIds.length; index++) {
      const listId = listIds[index];
      if (!boardListGroupByOrder[boardId][groupBy][listId]) {
        boardListGroupByOrder[boardId][groupBy][listId] = { cardIds: [] };
      }
    }
    Vue.set(state, 'boardListGroupByOrder', boardListGroupByOrder);
    localStorage.setItem(`groupByOrder-${teamId}`, JSON.stringify(boardListGroupByOrder));
  },

  addGroupByList(state, { boardId, listId, groupBy, teamId }) {
    const boardListGroupByOrder = { ...state.boardListGroupByOrder };
    if (!boardListGroupByOrder[boardId]?.[groupBy]) return;

    const existingList = boardListGroupByOrder[boardId][groupBy].order.find(
      (id: any) => id === listId
    );
    if (!existingList) {
      boardListGroupByOrder[boardId][groupBy].order.push(listId);
      boardListGroupByOrder[boardId][groupBy] = {
        ...boardListGroupByOrder[boardId][groupBy],
        [listId]: { cardIds: [] },
      };
      Vue.set(state, 'boardListGroupByOrder', boardListGroupByOrder);
      localStorage.setItem(`groupByOrder-${teamId}`, JSON.stringify(boardListGroupByOrder));
    }
  },

  removeCardFromGroupByList(state, { boardId, cardId, listId, groupBy, teamId }) {
    const boardListGroupByOrder = { ...state.boardListGroupByOrder };
    if (!boardListGroupByOrder[boardId]?.[groupBy]?.[listId]) return;

    const index = boardListGroupByOrder[boardId][groupBy][listId].cardIds.findIndex(
      (id: any) => id === cardId
    );

    if (index > -1) {
      boardListGroupByOrder[boardId][groupBy][listId]?.cardIds.splice(index, 1);
    }

    Vue.set(state, 'boardListGroupByOrder', boardListGroupByOrder);
    localStorage.setItem(`groupByOrder-${teamId}`, JSON.stringify(boardListGroupByOrder));
  },

  addCardToGroupByList(state, { boardId, cardId, listId, groupBy, teamId }) {
    const boardListGroupByOrder = { ...state.boardListGroupByOrder };
    if (!boardListGroupByOrder[boardId]?.[groupBy]) return;

    if (!boardListGroupByOrder[boardId][groupBy][listId]) {
      boardListGroupByOrder[boardId][groupBy].order.push(listId);
      boardListGroupByOrder[boardId][groupBy][listId] = { cardIds: [] };
    }

    const exsistingCard = boardListGroupByOrder[boardId][groupBy][listId].cardIds.find(
      (id: any) => id === cardId
    );

    if (!exsistingCard) {
      boardListGroupByOrder[boardId][groupBy][listId].cardIds.push(cardId);
      Vue.set(state, 'boardListGroupByOrder', boardListGroupByOrder);
      localStorage.setItem(`groupByOrder-${teamId}`, JSON.stringify(boardListGroupByOrder));
    }
  },

  updateGroupByListOrder(state, { boardId, listId, newIndex, oldIndex, groupBy, teamId }) {
    const boardListGroupByOrder = { ...state.boardListGroupByOrder };
    if (!boardListGroupByOrder[boardId]?.[groupBy]?.order) return;

    if (
      oldIndex < 0 ||
      oldIndex >= boardListGroupByOrder[boardId][groupBy].order.length ||
      newIndex < 0 ||
      newIndex >= boardListGroupByOrder[boardId][groupBy].order.length
    ) {
      return; // Return early if indexes are out of bounds
    }

    boardListGroupByOrder[boardId][groupBy].order.splice(oldIndex, 1);
    boardListGroupByOrder[boardId][groupBy].order.splice(newIndex, 0, listId);

    Vue.set(state, 'boardListGroupByOrder', boardListGroupByOrder);
    localStorage.setItem(`groupByOrder-${teamId}`, JSON.stringify(boardListGroupByOrder));
  },

  setListCardsGroupByOrder(state, { boardId, listId, cardIds, teamId, groupBy }) {
    const boardListGroupByOrder = { ...state.boardListGroupByOrder };

    if (!boardListGroupByOrder[boardId]?.[groupBy]?.[listId]) return;

    if (boardListGroupByOrder[boardId][groupBy][listId]?.cardIds?.length === 0) {
      boardListGroupByOrder[boardId][groupBy][listId].cardIds = cardIds;
    } else {
      const currentArray = boardListGroupByOrder[boardId][groupBy][listId].cardIds;

      // Create a Set from the current array to remove duplicates
      const newOrderSet = new Set(cardIds);

      // Filter the current array to remove elements that are not in the new array
      const orderedArray = currentArray.filter((id: string) => newOrderSet.has(id));

      // Add elements from the new array that are not in the current array
      cardIds.forEach((id: string) => {
        if (!orderedArray.includes(id)) {
          orderedArray.push(id);
        }
      });
      boardListGroupByOrder[boardId][groupBy][listId].cardIds = orderedArray;
    }

    Vue.set(state, 'boardListGroupByOrder', boardListGroupByOrder);
    localStorage.setItem(`groupByOrder-${teamId}`, JSON.stringify(boardListGroupByOrder));
  },

  reorderCardsGroupByList(state, { boardId, listId, cardId, newIndex, oldIndex, groupBy, teamId }) {
    if (!state.boardListGroupByOrder[boardId]?.[groupBy]?.[listId]) return;

    state.boardListGroupByOrder[boardId][groupBy][listId].cardIds.splice(oldIndex, 1);
    state.boardListGroupByOrder[boardId][groupBy][listId].cardIds.splice(newIndex, 0, cardId);

    localStorage.setItem(`groupByOrder-${teamId}`, JSON.stringify(state.boardListGroupByOrder));
  },

  updateCardsGroupByOrder(
    state,
    { boardId, originalListId, destinationListId, cardId, newIndex, groupBy, teamId }
  ) {
    if (!state.boardListGroupByOrder[boardId]?.[groupBy]) return;

    if (state.boardListGroupByOrder[boardId][groupBy][originalListId]) {
      const index =
        state.boardListGroupByOrder[boardId][groupBy][originalListId].cardIds.indexOf(cardId);
      if (index > -1) {
        state.boardListGroupByOrder[boardId][groupBy][originalListId].cardIds.splice(index, 1);
      }
    }

    if (state.boardListGroupByOrder[boardId][groupBy][destinationListId]) {
      const index =
        state.boardListGroupByOrder[boardId][groupBy][destinationListId].cardIds.indexOf(cardId);
      if (index > -1) {
        state.boardListGroupByOrder[boardId][groupBy][destinationListId].cardIds.splice(index, 1);
      }
      state.boardListGroupByOrder[boardId][groupBy][destinationListId].cardIds.splice(
        newIndex,
        0,
        cardId
      );
    }

    localStorage.setItem(`groupByOrder-${teamId}`, JSON.stringify(state.boardListGroupByOrder));
  },

  setCurrentGroupByListIdCardActionEdit(state, listId) {
    state.currentGroupByListIdCardActionEdit = listId;
  },

  setCurrentCardUniqueRowId(state, uniqueRowId) {
    state.currentCardUniqueRowId = uniqueRowId;
  },

  setNewBoardObjectField(state, { fieldName, value }) {
    Vue.set(state.newBoardObject, fieldName, value);
  },

  resetNewBoardObject(state) {
    state.newBoardObject = {};
  },

  setSprintStatusPickerDropdownId(state, value) {
    state.sprintStatusPickerDropdownId = value;
  },

  setProjectSprintSettings(state, { projectId, settings }) {
    const sprintSettings = { ...state.sprintSettings };
    sprintSettings[projectId] = settings;
    Vue.set(state, 'sprintSettings', sprintSettings);
  },

  setSpaceSprints(state, { projectId, sprints }) {
    const oldSprints = state.spaceSprints[projectId] || {};
    const newSprints = { ...oldSprints, ...sprints };
    Vue.set(state.spaceSprints, projectId, newSprints);
  },

  addSprintToSpaceSprints(state, { projectId, sprint }) {
    if (!state.spaceSprints[projectId]) {
      Vue.set(state.spaceSprints, projectId, {});
    }
    Vue.set(state.spaceSprints[projectId], sprint.id, sprint);
  },

  setSprintListsFlat(state, lists) {
    const newSprintLists = { ...state.sprintLists, ...lists };
    Vue.set(state, 'sprintLists', newSprintLists);
  },

  setSprintForEdit(state, item) {
    state.sprintForEditId = item;
  },

  localUpdateSprint(state, { projectId, sprintId, prop, value }) {
    const sprint = state.spaceSprints[projectId][sprintId];
    if (sprint) {
      sprint[prop] = value;
    }
    Vue.set(state.spaceSprints[projectId], sprintId, sprint);

    const newFlatBoards = { ...state.flatBoards, [sprint.id]: { ...sprint } };
    Vue.set(state, 'flatBoards', newFlatBoards);
  },
};

export default mutations;
