<template>
  <div class="dropdown-wrap">
    <v-popover
      v-if="items.length"
      :open="showDropdown"
      trigger="click"
      :placement="dropdownPlacement"
      :popover-class="'popover-dropdown list-dropdown-popover'"
    >
      <template slot="popover">
        <ListDropdown
          v-if="showDropdown"
          v-click-outside="closeDropdown"
          class="list-dropdown-popover dropdown-position"
          :items="items"
          :position="dropdownPosition"
          :width="dropdownWidth"
          :style="dropdownStyle"
          :selected-item="selectedItem"
          :disable-scroll="disableScroll"
          @click="handleItemClick"
          @closeEscape="closeDropdown"
        />
      </template>
      <div ref="dropdownTrigger">
        <slot
          name="trigger"
          :toggle="toggleDropdown"
        >
          <div
            :class="dropdownClass"
            :style="dropdownWrapStyle"
            @click="toggleDropdown"
          >
            <div
              class="label-wrap"
              :class="{ 'without-label': !label }"
            >
              <span
                v-if="label"
                class="dropdown-label"
              >
                {{ label }}</span
              >
              <span
                :class="{
                  'dropdown-selected-disabled': disabled,
                  'dropdown-selected': !disabled,
                  'text-text-placeholder': selectedItem === -1,
                }"
              >
                {{ selectedItemText }}
              </span>
            </div>
            <em
              v-if="!disabled"
              :style="getCustomIconStyle(iconPath('chevron-up'))"
              :class="
                showDropdown
                  ? 'custom-icons dropdown-wrap-arrow-down'
                  : 'custom-icons dropdown-wrap-arrow-down rotate-180'
              "
            />
          </div>
        </slot>
      </div>
    </v-popover>
    <div
      v-else
      ref="dropdownTrigger"
      class="dropdown-button usn empty-list"
      :style="dropdownWrapStyle"
      @click="toggleDropdown"
    >
      <div
        class="label-wrap"
        :class="{ 'without-label': !label }"
      >
        <span
          v-if="label"
          class="dropdown-label"
        >
          {{ label }}</span
        >
        <span class="dropdown-selected"> {{ emptyListText || translate('emptyList') }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import ListDropdown from '@/components/widgets/ListDropdown';
import customIconsMixin from '@/mixins/customIconsMixin';

export default {
  name: 'ListDropdownWrap',
  components: {
    ListDropdown,
  },
  mixins: [customIconsMixin],
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: Number,
      required: false,
      default: 44,
    },
    dropdownWidth: {
      type: Number,
      required: false,
      default: 290,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    dropdownPosition: {
      type: String,
      required: false,
      default: 'list-dropdown-wrap',
    },
    selectedItem: {
      type: Number,
      required: false,
      default: -1,
    },
    dropdownStyle: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    emptyListText: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    dropdownPlacement: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    dropdownClass() {
      const pt = this.disabled ? '' : 'pt';
      return this.showDropdown
        ? 'dropdown-button pt usn dropdown-opened'
        : `dropdown-button ${pt} usn dropdown-closed`;
    },
    dropdownWrapStyle() {
      return {
        height: `${this.height}px`,
      };
    },
    selectedItemText() {
      if (this.selectedItem === -1) return this.translate(this.placeholder);
      return this.items[this.selectedItem] ? this.items[this.selectedItem].label : '';
    },
  },
  methods: {
    handleItemClick(item, index) {
      this.$emit('item-click', item, index);
      this.closeDropdown();
    },

    closeDropdown(e = {}) {
      if (
        e.target &&
        (this.$refs.dropdownTrigger.contains(e.target) || this.$refs.dropdownTrigger === e.target)
      ) {
        return;
      }
      this.showDropdown = false;
    },

    toggleDropdown($event) {
      if (!this.items.length || this.disabled) return;
      this.$emit('toggle-dropdown', $event);
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>

<style lang="scss">
.dropdown-wrap {
  position: relative;

  .dropdown-opened {
    border: 1px solid #73b9ff;
    box-shadow: 0px 0px 0px 4px #e5f3ff80;
  }

  .dropdown-closed {
    border: 1px solid #dadcdf;
    box-shadow: 0px 1px 2px 0px #1018280d;
  }

  .dropdown-button {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 0px 14px;
    align-items: center;
    @apply text-text-default bg-important-bg;

    &.empty-list {
      border: 1px solid $error400;
    }

    .label-wrap {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &.without-label {
        flex-direction: row;
        align-self: center;
      }

      .dropdown-label {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #929292;
        margin-bottom: 1px;
      }

      .dropdown-selected {
        font-size: 14px;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @apply text-text-default;
      }

      .dropdown-selected-disabled {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @apply text-text-default;
      }

      &.empty-list {
        align-self: center;
      }
    }

    .dropdown-expand-icon {
      font-size: 24px;
      color: #707070;
      align-self: center;
    }
  }
}
</style>
