<template>
  <section
    ref="styles"
    style="display: none"
  >
    <slot />
  </section>
</template>

<script>
export default {
  name: 'CustomStyle',
  mounted() {
    const styleTag = document.createElement('style');
    styleTag.textContent = this.$refs.styles.textContent;
    this.$refs.styles.textContent = null;
    this.$refs.styles.appendChild(styleTag);
  },
};
</script>
