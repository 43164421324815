export enum ResourceType {
  Board = 'board',
  List = 'list',
  Card = 'card',
  Page = 'page',
  Project = 'project',
  Space = 'space',
  ChildPage = 'child-page',
  Comment = 'comment',
  CardTemplate = 'cardTemplate',
  PageTemplate = 'pageTemplate',
  Epic = 'epic',
  View = 'view',
  EpicsBoard = 'epicsBoard',
  QuickChildCard = 'quickChildCard',
  Sprints = 'sprints',
  Sprint = 'sprint',
  SprintCard = 'sprintcard',
  CardComment = 'card-comment',
  PageComment = 'page-comment',
}
