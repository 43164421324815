import getDashedTitle from '@/utilities/getDashedTitle';
import EventBus from '@/utilities/eventBus';
import cardStatuses from '@/mixins/cardStatuses';
import { ResourceType } from '@/types/resources';

export default {
  mixins: [cardStatuses],
  data() {
    return {
      currentlyViewed: () => ({}),
      recentlyViewedGenItemTimeout: null,
    };
  },
  computed: {
    displayQuickCard() {
      return this.$store.getters.getDisplayQuickCard;
    },

    gettersAndIconsMap() {
      return {
        [this.$constants.routeNames.view]: {
          getter: 'getSelectedView',
          icon: 'view',
        },
        [this.$constants.routeNames.page]: {
          getter: 'getPage',
          icon: (page) => page?.icon?.src || 'page',
          iconType: (page) => page?.icon?.type || 'icon',
        },
        [this.$constants.routeNames.board]: {
          getter: 'currentBoard',
          icon: 'board',
        },
        [this.$constants.routeNames.space]: {
          getter: 'getCurrentProject',
          icon: (project) => project?.icon?.src || '',
          iconType: (project) => project.icon?.type || '',
        },
        [this.$constants.routeNames.boardCard]: {
          getter: 'getCurrentCard',
          icon: (card) => this.cardStatusIcon(card),
        },
        [this.$constants.epicRoute.name]: {
          getter: 'getCurrentCard',
          icon: (epic) => this.cardStatusIcon(epic),
          icon2: (epic) => epic?.icon?.src || this.$constants.epicDefaultIcon,
          icon2Type: (epic) => epic?.icon?.type || '',
          icon2Color: (epic) => epic?.icon?.color || '',
        },
      };
    },

    recentlyViewedLS() {
      const currentTeamIdLS = this.teamId ?? localStorage.getItem('currentTeamId');
      return currentTeamIdLS
        ? JSON.parse(localStorage.getItem(`recentlyViewed:${currentTeamIdLS}`) || '[]')
        : [];
    },

    teamId() {
      return this.$store.getters.getTeamID;
    },

    teamSlug() {
      return this.$store.getters.getTeamSlug;
    },

    pages() {
      return this.$store.getters.getPages;
    },

    cards() {
      return this.$store.getters.getCards;
    },

    boards() {
      return this.$store.getters.getFlatBoards;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        const teamId = this.teamId;
        this.recentlyViewedGenItemTimeout = setTimeout(() => {
          this.generateItemForRecentlyViewedDropdown(newVal.name, newVal.path, teamId);
        }, 250);
      },
    },
    displayQuickCard(newVal) {
      if (newVal) {
        const { id, title, type } = this.$store.getters.getCurrentCard;
        const isEpic = type === ResourceType.Epic;
        const routePath = isEpic ? this.$cons.epicRoute.path : 'card';

        const routeName = isEpic
          ? this.$constants.epicRoute.name
          : this.$constants.routeNames.boardCard;

        const path = `/${this.teamSlug}/${routePath}-${id}-${getDashedTitle(title)}/`;
        this.generateItemForRecentlyViewedDropdown(routeName, path);
        return;
      }

      this.addItemToRecentlyViewedDropdown();
    },
    teamId: {
      handler(newVal) {
        if (!newVal) return;
        this.setRecentlyViewed(this.recentlyViewedLS);
      },
      immediate: true,
    },
  },
  mounted() {
    EventBus.$on('addQuickCardToRecentlyViewed', this.saveQuickCardCreatedToRecentlyViewed);
    EventBus.$on('addQuickPageToRecentlyViewed', this.saveQuickPageCreatedToRecentlyViewed);
  },
  beforeDestroy() {
    EventBus.$off('addQuickCardToRecentlyViewed', this.saveQuickCardCreatedToRecentlyViewed);
    EventBus.$off('addQuickPageToRecentlyViewed', this.saveQuickPageCreatedToRecentlyViewed);

    this.setRecentlyViewed([]);
    this.generateItemForRecentlyViewedDropdown(this.$route.name, this.$route.path);
    clearTimeout(this.recentlyViewedGenItemTimeout);
  },
  methods: {
    setRecentlyViewed(payload) {
      this.$store.dispatch('setElectronRecentlyViewed', payload);
    },

    cardStatusIcon(card) {
      const currentCardList = this.$store.getters.lists[card.list_id] || null;
      const cardStatus = currentCardList || this.cardStatuses[card.status] || {};

      return (
        cardStatus.icon ||
        (this.cardStatuses[currentCardList?.behavior] || {}).icon ||
        this.cardStatuses.backlog.icon ||
        ''
      );
    },

    generateItemForRecentlyViewedDropdown(type, path, teamId = this.teamId) {
      // adds the previously stored item to the dropdown
      this.addItemToRecentlyViewedDropdown(teamId);

      const config = this.gettersAndIconsMap[type];
      if (!config) return;

      const item = this.$store.getters[config.getter];
      const { id, title, name, project_id } = item;

      if (!id || (!title && !name && type !== this.$constants.routeNames.page)) return;

      const label =
        title ||
        name ||
        (type === this.$constants.routeNames.page ? this.translate('pageTitlePlaceholder') : '');

      const configIconType = typeof config.iconType === 'function' ? config.iconType(item) : '';

      const icon = {
        name: typeof config.icon === 'function' ? config.icon(item) : config.icon,
        color: item.icon?.color || item.list_color || '',
        type: item.icon?.type ? item.icon.type : configIconType,
      };

      let icon2;
      if (item.type === this.$constants.epicRoute.name) {
        icon2 = {
          name: typeof config.icon2 === 'function' ? config.icon2(item) : config.icon2,
          color: item.icon2?.color || '',
          type: item.icon2?.type ? item.icon2.type : configIconType,
        };
      }

      const payload = {
        label,
        icon,
        icon2,
        id,
        type,
        path,
        ...(project_id && { project_id }),
      };

      // sets the current item to be added later on
      this.currentlyViewed = payload;
    },

    addItemToRecentlyViewedDropdown(teamId = this.teamId) {
      if (Object.keys(this.currentlyViewed).length) {
        const { currentlyViewed } = this;
        this.addToElectronRecentlyViewed({ currentlyViewed, teamId });
        this.currentlyViewed = {};
      }
    },

    addToElectronRecentlyViewed(payload) {
      if (this.isResourceDeleted(payload.currentlyViewed)) return;
      this.$store.dispatch('addToElectronRecentlyViewed', payload);
    },

    isResourceDeleted(currentlyViewed) {
      if (currentlyViewed.type === this.$constants.routeNames.page) {
        return !this.pages[currentlyViewed.id];
      } else if (currentlyViewed.type === this.$constants.routeNames.boardCard) {
        return !this.cards[currentlyViewed.id];
      } else if (currentlyViewed.type === this.$constants.routeNames.board) {
        return this.boards[`${currentlyViewed.id}_notFound`];
      }
      return false;
    },

    saveQuickCardCreatedToRecentlyViewed(card) {
      const path = `/${this.teamSlug}/card-${card.id}-${getDashedTitle(card.title)}`;
      const icon = {
        name: this.cardStatusIcon(card),
        color: card.list_color || '',
      };
      const payload = {
        label: card.title,
        path,
        icon,
        type: this.$constants.routeNames.boardCard,
        id: card.id,
      };

      this.addToElectronRecentlyViewed({
        currentlyViewed: payload,
        teamId: this.teamId,
      });
    },

    saveQuickPageCreatedToRecentlyViewed(page) {
      const label = page.title || this.translate('pageTitlePlaceholder');
      const path = `/${this.teamSlug}/page-${page.id}-${getDashedTitle(label)}`;
      const icon = {
        name: 'page',
        color: '',
        type: '',
      };
      const payload = {
        label,
        path,
        icon,
        type: this.$constants.routeNames.page,
        id: page.id,
      };

      this.addToElectronRecentlyViewed({
        currentlyViewed: payload,
        teamId: this.teamId,
      });
    },
  },
};
