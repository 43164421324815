import { defaultCardStatuses, defaultBoardLists } from '@/utilities/defaultCardStatuses';

export default {
  computed: {
    cardStatuses() {
      return defaultCardStatuses;
    },

    defaultLists() {
      return defaultBoardLists;
    },
  },
};
