<template>
  <div class="dropdown-list-backgrounds">
    <div
      v-for="(item, index) in selectItems"
      :id="item.id"
      :key="index"
      class="board-background pt"
      @click.stop="handleClick(item)"
    >
      <em
        v-if="item.selected"
        class="material-icons background-checkbox"
      >
        check_circle
      </em>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectButton',
  props: {
    selectItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClick(item) {
      this.$emit('selectItemClick', item);
    },
  },
};
</script>

<style lang="scss">
.dropdown-list-backgrounds {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 8px 7px 8px;

  .board-background {
    width: 116px;
    height: 70px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .background-checkbox {
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #ffffff;
      background-color: #ffffff;
      color: $stBlue;
      em {
        color: #ffffff;
      }
    }
  }
}
</style>
