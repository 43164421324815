import { CardStatus } from '@superthread-com/api';

const defaultCardStatuses = {
  backlog: {
    label: 'Backlog',
    icon: 'circle-dotted',
    id: 'backlog',
    color: '#98A2B3',
  },
  committed: {
    label: 'Not started',
    icon: 'disc',
    id: CardStatus.Committed,
    color: '#98A2B3',
  },
  started: {
    label: 'In progress',
    icon: 'play-circle',
    id: CardStatus.Started,
    color: '#498CF7',
  },
  completed: {
    label: 'Done',
    icon: 'check-circle',
    id: CardStatus.Completed,
    color: '#32D583',
  },
  cancelled: {
    label: 'Cancelled',
    icon: 'x-circle',
    id: CardStatus.Cancelled,
    color: '#F79009',
  },
  rolledover: {
    label: 'Rolled over',
    icon: 'arrow-right-circle',
    id: 'rolledover',
    color: '#475467',
  },
};

const defaultBoardLists = [
  {
    id: defaultCardStatuses.backlog.id,
    label: defaultCardStatuses.backlog.label,
    title: 'Backlog',
    behavior: defaultCardStatuses.backlog.id,
    icon: defaultCardStatuses.backlog.icon,
    color: defaultCardStatuses.backlog.color,
  },
  {
    id: defaultCardStatuses.committed.id,
    label: defaultCardStatuses.committed.label,
    title: 'To do',
    behavior: defaultCardStatuses.committed.id,
    icon: defaultCardStatuses.committed.icon,
    color: defaultCardStatuses.committed.color,
  },
  {
    id: defaultCardStatuses.started.id,
    label: defaultCardStatuses.started.label,
    title: 'Doing',
    behavior: defaultCardStatuses.started.id,
    icon: defaultCardStatuses.started.icon,
    color: defaultCardStatuses.started.color,
  },
  {
    id: defaultCardStatuses.completed.id,
    label: defaultCardStatuses.completed.label,
    title: 'Done',
    behavior: defaultCardStatuses.completed.id,
    icon: defaultCardStatuses.completed.icon,
    color: defaultCardStatuses.completed.color,
  },
  {
    id: defaultCardStatuses.cancelled.id,
    label: defaultCardStatuses.cancelled.label,
    title: 'Cancelled',
    behavior: defaultCardStatuses.cancelled.id,
    icon: defaultCardStatuses.cancelled.icon,
    color: defaultCardStatuses.cancelled.color,
  },
];

const defaultSprintStatuses = [
  { title: defaultCardStatuses.committed.label, status: defaultCardStatuses.committed.id },
  { title: defaultCardStatuses.started.label, status: defaultCardStatuses.started.id },
  { title: defaultCardStatuses.completed.label, status: defaultCardStatuses.completed.id },
  { title: defaultCardStatuses.cancelled.label, status: defaultCardStatuses.cancelled.id },
  { title: defaultCardStatuses.rolledover.label, status: defaultCardStatuses.rolledover.id },
];

export { defaultCardStatuses, defaultBoardLists, defaultSprintStatuses };
