const getMemberLabel = (member, prioritiseFirstLastName = false) => {
  if (!member) return '';
  let name = '';
  const { first_name, last_name, display_name, email } = member;
  if (first_name || last_name) {
    name = `${first_name || ''} ${last_name || ''}`;
  }
  const memberLabel = prioritiseFirstLastName
    ? `${name || display_name || email || ''}`
    : `${display_name || name || email || ''}`;
  return memberLabel.trim().replace(/\s+/g, ' ');
};

export default getMemberLabel;
