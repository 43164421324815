const generateDefaultMyWorkFilters = (userId, tabId) => {
  switch (tabId) {
    case 'AssignedToMe':
      return {
        include: {
          members: [userId],
        },
        exclude: {
          statuses: ['completed', 'cancelled'],
        },
      };
    case 'CreatedByMe':
      return {
        include: {
          creators: [userId],
        },
        exclude: {
          statuses: ['completed', 'cancelled'],
        },
      };
    default:
      return {
        include: {},
        exclude: {},
      };
  }
};

export default generateDefaultMyWorkFilters;
