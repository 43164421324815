import { getCurrentInstance, computed, toRef, Ref } from 'vue';
import useTranslate from '@/utilities/composables/useTranslate';
import {
  OnboardingActionType,
  OnboardingActionStatus,
  OnboardingAction,
} from '@/types/onboardingActions';
import constants from '@/utilities/constants';
import Router, { Route } from 'vue-router';
import { Team, User } from '@superthread-com/api';

// ** ONBOARDING ACTIONS - ON CLICK FNs **

const goToWorkspaceSettingsRoute = (router: Router, route: Ref<Route>) => {
  if (constants.settingsWorkspaceRouteName !== route.value.name)
    router.push({
      name: constants.settingsWorkspaceRouteName,
    });
};

const goToSettingsRoute = (router: Router, route: Ref<Route>) => {
  if (constants.settingsRouteName != route.value.name)
    router.push({
      name: constants.settingsRouteName,
    });
};

const goToImportSettingsRoute = (router: Router, route: Ref<Route>) => {
  if (constants.settingsImportRouteName != route.value.name)
    router.push({
      name: constants.settingsImportRouteName,
    });
};

const goToKeyboardShortcutRoute = (router: Router, route: Ref<Route>, dispatch: any) => {
  dispatch('completeOnboardingActions', [OnboardingActionType.ViewKeyboardShortcuts]);
  window.open(constants.keyboardShortcutCheatsheetUrl, '_blank');
};

const goToNotificationsSettingsRoute = (router: Router, route: Ref<Route>) => {
  if (constants.settingsNotificationsRouteName != route.value.name) {
    router.push({
      name: constants.settingsNotificationsRouteName,
    });
  }
};

const showQuickCardModal = (dispatch: any) => {
  dispatch('setQuickCard', true);
};

const showQuickPageModal = (dispatch: any) => {
  dispatch('setQuickPage', true);
};

const showInviteUserModal = (dispatch: any) => {
  dispatch('setAddMemberPopup', true);
};

export default function useOnboardingActions() {
  const _this = getCurrentInstance()?.proxy;
  if (!_this) return;

  const { getters, dispatch } = _this.$store;
  const { $router } = _this;
  const { translate } = useTranslate();
  const routeRef = toRef(_this, '$route');

  // ** ONBOARDING ACTIONS - MAPS **

  const onboardingActionsDataMap = {
    [OnboardingActionType.MentionAnything]: {
      icon: 'at-sign',
      onClick: () => openOnboardingActionsVideoPreview(OnboardingActionType.MentionAnything),
      title: translate(`${OnboardingActionType.MentionAnything}.title`),
      tooltip: translate(`${OnboardingActionType.MentionAnything}.tooltip`),
    },
    [OnboardingActionType.AddChildCard]: {
      icon: 'card-child',
      onClick: () => openOnboardingActionsVideoPreview(OnboardingActionType.AddChildCard),
      title: translate(`${OnboardingActionType.AddChildCard}.title`),
      tooltip: translate(`${OnboardingActionType.AddChildCard}.tooltip`),
    },
    [OnboardingActionType.CreateCard]: {
      icon: 'new-card-icon',
      onClick: () => showQuickCardModal(dispatch),
      title: translate(`${OnboardingActionType.CreateCard}.title`),
      tooltip: translate(`${OnboardingActionType.CreateCard}.tooltip`),
    },
    [OnboardingActionType.CreatePage]: {
      icon: 'page',
      onClick: () => showQuickPageModal(dispatch),
      title: translate(`${OnboardingActionType.CreatePage}.title`),
      tooltip: translate(`${OnboardingActionType.CreatePage}.tooltip`),
    },
    [OnboardingActionType.AddWorkspaceLogo]: {
      icon: 'workspace',
      onClick: () => goToWorkspaceSettingsRoute($router, routeRef),
      title: translate(`${OnboardingActionType.AddWorkspaceLogo}.title`),
      tooltip: translate(`${OnboardingActionType.AddWorkspaceLogo}.tooltip`),
    },
    [OnboardingActionType.CustomiseYourProfile]: {
      icon: 'user',
      onClick: () => goToSettingsRoute($router, routeRef),
      title: translate(`${OnboardingActionType.CustomiseYourProfile}.title`),
      tooltip: translate(`${OnboardingActionType.CustomiseYourProfile}.tooltip`),
    },
    [OnboardingActionType.ImportTasks]: {
      icon: 'upload-cloud',
      onClick: () => goToImportSettingsRoute($router, routeRef),
      title: translate(`${OnboardingActionType.ImportTasks}.title`),
      tooltip: translate(`${OnboardingActionType.ImportTasks}.tooltip`),
    },
    [OnboardingActionType.ViewKeyboardShortcuts]: {
      icon: 'mac-command',
      onClick: () => goToKeyboardShortcutRoute($router, routeRef, dispatch),
      title: translate(`${OnboardingActionType.ViewKeyboardShortcuts}.title`),
      tooltip: translate(`${OnboardingActionType.ViewKeyboardShortcuts}.tooltip`),
    },
    [OnboardingActionType.ConnectSlack]: {
      icon: 'slack',
      onClick: () => goToNotificationsSettingsRoute($router, routeRef),
      title: translate(`${OnboardingActionType.ConnectSlack}.title`),
      tooltip: translate(`${OnboardingActionType.ConnectSlack}.tooltip`),
    },
  } as Record<
    OnboardingActionType,
    { icon: string; onClick: Function; title: string; tooltip: string; extension?: string }
  >;

  // ** ONBOARDING ACTIONS METHODS AND COMPUTEDS **

  const userCreatedAfterOnboardingActionsRelease = computed(() => {
    const { time_created } = getters.getUser;
    return time_created > constants.onboardingActionsReleaseDate;
  });

  const getOnboardingActions = computed((): OnboardingAction[] => {
    return getters.getOnboardingActions;
  });

  const getNotDismissedOnboardingActions = computed((): OnboardingAction[] => {
    return getOnboardingActions.value.filter(
      ({ status }) => status !== OnboardingActionStatus.Dismissed
    );
  });

  const getNotCompletedOnboardingActions = computed((): OnboardingAction[] => {
    return getOnboardingActions.value.filter(
      ({ status }) => status === OnboardingActionStatus.NotCompleted
    );
  });

  // onboarding actions for which completion animation is still on
  const getCompletionRunningOnboardingActions = computed((): OnboardingAction[] => {
    return getNotDismissedOnboardingActions.value.filter(
      ({ animate_completion }) => animate_completion
    );
  });

  const getFullyCompletedOnboardingActions = computed((): OnboardingAction[] => {
    return getNotDismissedOnboardingActions.value.filter(
      ({ status }) => status === OnboardingActionStatus.Completed
    );
  });

  const dismissOnboardingAction = (type: OnboardingActionType) => {
    dispatch('dismissOnboardingAction', type);
  };

  const completeOnboardingActions = (types: OnboardingActionType) => {
    dispatch('completeOnboardingActions', types);
  };

  const openOnboardingActionsVideoPreview = (
    type: OnboardingActionType,
    extension: string = 'mp4'
  ) => {
    const url = `/videos/${type}.${extension}`;
    const id = `${constants.onboardingActionsVideoPrefix}-${type}`;
    dispatch('setPreviewAttachments', [
      {
        url,
        type: 'video',
        name: onboardingActionsDataMap[type].title,
        id,
      },
    ]);
    dispatch('setPreviewAttachmentId', {
      attachmentId: id,
    });
  };

  const team = computed((): Team => {
    return getters.getTeam;
  });

  const user = computed((): User => {
    return getters.getUser;
  });

  const teamLogo = computed(() => {
    return team?.value?.logo?.src ?? null;
  });

  const userProfileImage = computed(() => {
    return user?.value?.profile_image ?? null;
  });

  // skips onboarding actions that were already completed
  const shouldSkipOnboardingAction = (type: OnboardingActionType) => {
    if (type === OnboardingActionType.AddWorkspaceLogo && teamLogo.value) return true;
    if (type === OnboardingActionType.CustomiseYourProfile && userProfileImage.value) return true;
    return false;
  };

  const getOnboardingAction = (
    accumulator: OnboardingAction[],
    type: OnboardingActionType,
    onboardingActionsMap: any // onboarding actions from response
  ) => {
    // don't show if already set

    if (shouldSkipOnboardingAction(type)) return accumulator;

    //if any action is not returned in response, populate it with default not completed state
    const onboardingAction = {
      type,
      status: onboardingActionsMap[type]?.status ?? OnboardingActionStatus.NotCompleted,
    };

    accumulator.push(onboardingAction);
    return accumulator;
  };

  const fetchOnboardingActions = () => {
    if (!userCreatedAfterOnboardingActionsRelease.value) return;

    dispatch('fetchOnboardingActions').then((onboarding_actions: OnboardingAction[]) => {
      const onboardingActionsMap = onboarding_actions.reduce((accumulator: any, item) => {
        accumulator[item.type] = { ...item };
        return accumulator;
      }, {});

      const onboardingActions = Object.values(OnboardingActionType).reduce(
        (accumulator: OnboardingAction[], type) =>
          getOnboardingAction(accumulator, type, onboardingActionsMap),
        []
      );
      dispatch('setOnboardingActions', onboardingActions);
    });
  };
  // ** GET STARTED SIDEBAR SECTION **

  const getGetStartedExpanded = computed((): boolean => {
    return getters.getGetStartedExpanded;
  });

  const toggleGetStartedExpanded = () => {
    dispatch('setGetStartedExpanded', !getGetStartedExpanded.value);
  };

  const setGetStartedExpandedFromLS = () => {
    const isExpanded = JSON.parse(localStorage.getItem('getStartedExpanded') ?? 'null');
    if (isExpanded !== null) {
      dispatch('setGetStartedExpanded', isExpanded);
    }
  };

  const getGetStartedShowAll = computed(() => {
    return getters.getGetStartedShowAll;
  });

  const toggleGetStartedShowAll = () => {
    dispatch('setGetStartedShowAll', !getGetStartedShowAll.value);
  };

  const setGetStartedShowAllFromLS = () => {
    const showAll = JSON.parse(localStorage.getItem('getStartedShowAll') ?? 'null');
    if (showAll !== null) {
      dispatch('setGetStartedShowAll', showAll);
    }
  };

  // to be used on dev/staging only
  const setAllAsNotCompleted = (e: KeyboardEvent) => {
    if (e.code !== 'KeyN' || !e.ctrlKey || !e.altKey) return;

    dispatch(
      'setAsNotCompletedOnboardingAction',
      getOnboardingActions.value.map((a) => {
        return {
          type: a.type,
          status: OnboardingActionStatus.NotCompleted,
        };
      })
    );
  };

  return {
    OnboardingActionType,
    onboardingActionsDataMap,
    getOnboardingActions,
    getNotDismissedOnboardingActions,
    getNotCompletedOnboardingActions,
    getCompletionRunningOnboardingActions,
    getFullyCompletedOnboardingActions,
    dismissOnboardingAction,
    completeOnboardingActions,
    fetchOnboardingActions,
    userCreatedAfterOnboardingActionsRelease,
    openOnboardingActionsVideoPreview,

    toggleGetStartedExpanded,
    getGetStartedExpanded,
    setGetStartedExpandedFromLS,

    toggleGetStartedShowAll,
    getGetStartedShowAll,
    setGetStartedShowAllFromLS,
    setAllAsNotCompleted,
    teamLogo,
    userProfileImage,
    getOnboardingAction,
  };
}
