import EventBus from '@/utilities/eventBus';

export default {
  methods: {
    hideViewSettingsDropdown() {
      if (this.isViewOpen && this.$store.getters.getViewItemSettingsDropdown) {
        this.$store.dispatch('setViewItemSettingsDropdown', '');
      }
    },
    hideMemberDropdown() {
      if (this.$store.getters.getCurrentCardIdMemberDropdown) {
        this.$store.dispatch('setCurrentCardIdMemberDropdown', '');
        this.$store.dispatch('setCurrentCardMembersDropdown', {});
      }
    },
    hideCardActionsDropdown() {
      if (this.$store.getters.getCurrentCardIdActionEdit) {
        this.$store.dispatch('setCurrentCardIdForActionEdit', '');
      }
    },
    hideCardCustomSectionDropdown() {
      if (this.$store.getters.getCardCustomSectionDropdown) {
        this.$store.dispatch('setCardCustomSectionDropdown', '');
        this.$store.dispatch('setCurrentCardIdForPopup', '');
      }
    },
    hideSdUserActionsDropdown() {
      if (this.$store.getters.getSdUserActionsDropdown) {
        this.$store.dispatch('setSdUserActionsDropdown', false);
      }
    },
    hideFavouritesStettingsDropdown() {
      if (this.$store.getters.favouritesStettingsDropdown) {
        this.$store.dispatch('setFavouritesStettingsDropdown', '');
      }
    },
    hideSdProjectNewPageBoardDropdown() {
      if (this.$store.getters.getSdProjectNewPageBoardDropdown) {
        this.$store.dispatch('setSdProjectNewPageBoardDropdown', '');
      }
    },
    hideSdBoardSettingsDropdown() {
      if (this.$store.getters.getSdBoardSettingsDropdown) {
        this.$store.dispatch('setSdBoardSettingsDropdown', '');
      }
    },
    hideSdPageSettingsDropdown() {
      if (this.$store.getters.getSdPageSettingsDropdown) {
        this.$store.dispatch('setSdPageSettingsDropdown', '');
      }
    },
    hideSdProjectSettingsDropdown() {
      if (this.$store.getters.getSdProjectSettingsDropdown) {
        this.$store.dispatch('setSdProjectSettingsDropdown', '');
      }
    },
    hideFilterDropdown() {
      if (this.$store.getters.showFilterMenu) {
        this.$store.dispatch('showFilterMenu', false);
      }
      if (this.$store.getters.showSubHeaderFilterMenu) {
        this.$store.dispatch('showSubHeaderFilterMenu', false);
      }
    },
    hideStatusPickerDropdown() {
      if (this.$store.getters.getListCardStatusPickerDropdownId) {
        this.$store.dispatch('setListCardStatusPickerDropdownId', '');
      }
    },
    hideIconPickerDropdown() {
      if (this.$store.getters.getIconPickerDropdown) {
        this.$store.dispatch('setIconPickerDropdown', '');
      }
    },
    hideManageProjectsItemSettingsDropdown() {
      if (this.$store.getters.getManageProjectsItemSettingsDropdown) {
        this.$store.dispatch('setManageProjectsItemSettingsDropdown', '');
      }
    },
    hideTemplatesDropdown() {
      if (this.$store.getters.getShowTemplatesDropdown) {
        this.$store.dispatch('setShowTemplatesDropdown', '');
      }
    },
    hideHeaderProjectSettingsDropdown() {
      if (this.$store.getters.getHeaderProjectSettingsDropdown) {
        this.$store.dispatch('setHeaderProjectSettingsDropdown', false);
      }
    },
    hideCardsLayoutOptionsDropdown() {
      if (Object.keys(this.$store.getters.getCardsLayoutOptionsDropdown).length > 0) {
        this.$store.dispatch('setCardsLayoutOptionsDropdown', {});
      }
    },

    closeProjectSidebarDropdown() {
      if (this.$store.getters.getProjectSidebarDropdown) {
        this.$store.dispatch('setProjectSidebarDropdown', '');
      }
    },

    closeParentCardPopup() {
      if (this.$store.getters.getShowSetParentCardPopup) {
        this.$store.dispatch('setShowSetParentCardPopup', '');
      }
    },

    closeListActionsDropdown() {
      if (this.$store.getters.getCurrentListIdActionEdit) {
        this.$store.dispatch('setCurrentListIdForActionEdit', '');
      }
    },

    closeDupicateCardPopup() {
      if (this.$store.getters.getShowDuplicateCardPopup) {
        this.$store.dispatch('setShowDuplicateCardPopup', '');
      }
    },

    closeEmojiPicker() {
      if (this.$store.getters.getEmojiPickerDisplayed) {
        this.$store.dispatch('setEmojiPickerDisplayed', '');
      }
    },

    closePageUploadPopup() {
      if (Object.keys(this.$store.getters.getPageUploadPopup).length > 0) {
        this.$store.dispatch('setPageUploadPopup', {});
      }
    },

    closeRestOfCardDropdowns() {
      // for card sidebar members and parent card pill dropdown
      EventBus.$emit('closeCardActionsDropdown');
      EventBus.$emit('closeSidebarStatusPickerDropdown');
      EventBus.$emit('closeCommentActionsDropdown');

      if (this.$store.getters.getCardStatusPickerDropdownId) {
        this.$store.dispatch('setCardCardStatusPickerDropdownId', '');
      }

      if (this.$store.getters.getChildCardActionsDropdownId) {
        this.$store.dispatch('setChildCardActionsDropdownId', '');
      }
    },
    hideSidebarDropdowns() {
      this.hideSdUserActionsDropdown();
      this.hideFavouritesStettingsDropdown();
      this.hideSdProjectNewPageBoardDropdown();
      this.hideSdBoardSettingsDropdown();
      this.hideSdPageSettingsDropdown();
      this.hideSdProjectSettingsDropdown();
      this.hideIconPickerDropdown();
    },
    hideManageProjectsDropdowns() {
      this.hideIconPickerDropdown();
      this.hideManageProjectsItemSettingsDropdown();
      this.hideMemberDropdown();
    },

    hideAllDropdowns() {
      this.hideViewSettingsDropdown();
      this.hideMemberDropdown();
      this.hideCardActionsDropdown();
      this.hideCardCustomSectionDropdown();
      this.hideSdUserActionsDropdown();
      this.hideFavouritesStettingsDropdown();
      this.hideSdProjectNewPageBoardDropdown();
      this.hideSdBoardSettingsDropdown();
      this.hideSdPageSettingsDropdown();
      this.hideSdProjectSettingsDropdown();
      this.hideFilterDropdown();
      this.hideStatusPickerDropdown();
      this.hideIconPickerDropdown();
      this.hideManageProjectsItemSettingsDropdown();
      this.hideTemplatesDropdown();
      this.hideHeaderProjectSettingsDropdown();
      this.hideCardsLayoutOptionsDropdown();
      this.closeRestOfCardDropdowns();
      this.hideSidebarDropdowns();
      this.hideManageProjectsDropdowns();
      this.closeProjectSidebarDropdown();
      this.closeParentCardPopup();
      this.closeListActionsDropdown();
      this.closeDupicateCardPopup();
      this.closeEmojiPicker();
      this.closePageUploadPopup();
    },
  },
};
